import React from "react";
import PropTypes from 'prop-types';
import { Row, Col } from "react-bootstrap";
import CustomButton from 'src/components/Button/button.jsx';
// import './index.css';

const PopupConfirmation = ({
    message,
    confirmAction,
    cancelAction,
    confirmButtonText,
    cancelButtonText,
    children,
}) => {
    return (
        <div className="popup-wrapper">
            <div className="popup">
                <span>
                    {message}
                </span>
                <div style={{ textAlign: 'center' }}>
                    {children}
                </div>
                <Row>
                    <Col xs={6}>
                        <CustomButton style={{ width: "50%" }} onClick={confirmAction}>{confirmButtonText}</CustomButton>
                    </Col>
                    <Col xs={6}>
                        <CustomButton style={{ width: "50%" }} onClick={cancelAction}>{cancelButtonText}</CustomButton>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

PopupConfirmation.propTypes = {
    message: PropTypes.string,
    confirmAction: PropTypes.func,
    cancelAction: PropTypes.func,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    children: PropTypes.node,
};

PopupConfirmation.defaultProps = {
    message: '',
    confirmAction: () => { },
    cancelAction: () => { },
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
};

export default PopupConfirmation;