import { ethers } from "ethers";
import { roccaContractABI } from "../contract/roccaContractABI";

import { chains } from "../chain";

export const getRoccaVersion = async (roccaAddress) => {
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );

    const roccaInstance = new ethers.Contract(
        roccaAddress,
        roccaContractABI,
        provider
    );
    
    try {
        const tx = await roccaInstance.contractVersion();
        if (tx == "2.0.0") {
            return true;
        }
        return false;
        //@ts-ignore
    } catch (error) {
        console.log(error);
        console.log('Fetch rocca version unsuccessful');
        return false;
    }
};
