import React, { useEffect, useState, useCallback } from "react";
import Header from "../header/header";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import { useNavigate } from "react-router-dom";
import { logSignupClaimDone } from "src/googleAnalytics/signupEvents";
import { GET_TIMELINE } from "../../../axios/GET_API";

const MAX_RETRY_ATTEMPTS = 5;
const RETRY_INTERVAL = 10000; // 10 seconds

function ClaimStatus() {
  const [status, setStatus] = useState({
    isLoading: true,
    isProfileComplete: false,
    timelineData: null,
    retryCount: 0,
    error: null
  });

  const navigate = useNavigate();
  const spinner = useSpinner();

  const checkTimelineStatus = useCallback(async () => {
    try {
      spinner.setLoadingState(true);
      const response = await GET_TIMELINE(1);
      
      setStatus(prev => ({
        ...prev,
        isLoading: false,
        timelineData: response,
        isProfileComplete: response?.data?.is_profile_complete || false,
        retryCount: prev.retryCount + 1
      }));

      if (response?.data?.is_profile_complete) {
        setTimeout(() => {
          spinner.setLoadingState(false);
          logSignupClaimDone();
          navigate('/timeline');

          localStorage.setItem("profile_completed", true);
          return null;
        }, 3000); // 3 seconds delay
      }
    } catch (error) {
      setStatus(prev => ({
        ...prev,
        isLoading: false,
        error: error,
        retryCount: prev.retryCount + 1
      }));
      spinner.setLoadingState(false);
    }
  }, [navigate, spinner]);

  useEffect(() => {
    const shouldContinuePolling = 
      !status.isProfileComplete && 
      status.retryCount < MAX_RETRY_ATTEMPTS;

    if (shouldContinuePolling) {
      const statusCheckInterval = setInterval(checkTimelineStatus, RETRY_INTERVAL);
      return () => clearInterval(statusCheckInterval);
    }
  }, [status.isProfileComplete, status.retryCount, checkTimelineStatus]);

  useEffect(() => {
    checkTimelineStatus();
  }, []);


  return (
    <div className="signup-screen-wrapper">
      
      <div style={{position: "absolute", width: "100%", left: "0px", padding: "0px 20px" }}><Header /></div>
      
      <div className="content-wrap">
        <div className="text-info-popup">
          <h1 className="page-title-big" style={{ marginBottom: "10px", textAlign: "center" }}>Congratulations</h1>
          <span>
            Your claim has been processed. Please wait while we finalize your account setup.
          </span>
        </div>

        {status.error && (
          <div className="error-info" style={{ paddingTop: "20px" }}>
            An error occurred. Please try login again.
          </div>
        )}
      </div>
    </div>
  );
}

export default ClaimStatus;