import React, { useEffect } from "react";
import { Navigate, useParams, useLocation } from 'react-router-dom';

import Home from "../pages/signup/signup";

import { launchNextPage } from "src/PageLauncher";


const AuthRedirect = () => {
  const params = useParams();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const profileCompleted = localStorage.getItem("profile_completed") === "true";

  
  // Processing URL refCode
  useEffect(() => {
    // Check if we have a refCode from URL path parameter
    if (params.refCode) {
     localStorage.setItem('referralCode', params.refCode);
     console.log('Referral code saved from path:', params.refCode);
    } else {
     // Fallback to check query parameters (for backward compatibility)
     const queryParams = new URLSearchParams(location.search);
     const refCode = queryParams.get('ref');
     
     if (refCode) {
       localStorage.setItem('referralCode', refCode);
       console.log('Referral code saved from query:', refCode);
     }
    }
  }, [params, location]);
  
  // If token exists, redirect to timeline
  if (token && profileCompleted) {
    return <Navigate to="/timeline" replace />;
  }
  
  // Otherwise, show the home/login page
  return <Home />;
};

export default AuthRedirect;