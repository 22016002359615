import React, { useState, useEffect } from "react"

import { useTranslation } from "react-i18next";

import WalletBalance from "./WalletBalance";
import FlicsDisplayBoard from "./FlicsDisplayBoard";
import FlicsButtons from "./FlicsButtons";
import CustomButton from "src/components/Button/button";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"

import RoccaRequestReceived from "./RoccaRequestReceived";
import RoccaRequestSent from "./RoccaRequestSent";

import { GET_FLICS_LIST } from "src/axios/GET_API";

import "../flics.css"


function Flics({ setSliderPage, amount, sendFlicsDetails, flicsAmount, flicsAmountHours, setFlicsRequestSender, setFlicsDeployed }) {


  const [flicList, setFlicsList] = useState([]);
  const [onActiveFlics, setOnActiveFlics] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const flicsListAccepted = await GET_FLICS_LIST();
        setFlicsList(flicsListAccepted);

        // Check if any item in the list has an "active" status
        const isActive = flicsListAccepted.some(flic => flic.status === "active");
        setOnActiveFlics(isActive);
      } catch (error) {
        console.error('Error fetching flics request list:', error);
      }
    };

    fetchData(); 
  }, []);



  const changeToActivateFlics = () => {
    setSliderPage("activateflics");
  }
  const changeToBuyDrpd = () => {
    setSliderPage("buydrpd");
  }
  const changeToConnectWallet = () => {
    setSliderPage("connectwallet");
  }
  const changeToDeleteFlics = () => {
    setSliderPage("deleteflics");
  }
  const changeToDeployedFlics = () => {
    setSliderPage("flicsmain");
  }
  const changeToFlicsMain = () => {
    setSliderPage("flicsmain");
  }
  const changeToFlicsHistory = () => {
    setSliderPage("flicshistory");
  }
  const changeToSharedFlics = () => {
    setSliderPage("sharedflics");
  }
  const changeToShareFlics = () => {
    setSliderPage("shareflics");
  }
  const changeToFlicsDetails = (flics) => {
    setFlicsRequestSender(flics);
    setSliderPage("flicsRequestDetails");
  }
  const changeToRoccaSentDetails = (flics) => {
    setFlicsRequestSender(flics);
    setSliderPage("roccaSentDetails");
  }

  const changeToFlicsDeployed = (flics) => {
    setFlicsDeployed(flics);
    setSliderPage("flicsDeployedDeployed");
  }


  return (
    <>
      <div className="inner-pages-container">
        <WalletBalance />

        <div className="inner-pages-container-wrap">

          <FlicsDisplayBoard amount={amount} flicsAmount={flicsAmount} flicsRequestDeployed={changeToFlicsDeployed} flicList={flicList} />
          
          <FlicsButtons
            activateFlics={changeToActivateFlics}
            flicsAmount={flicsAmount}
            flicsAmountHours={flicsAmountHours}
            shareFlics={changeToShareFlics}
            flicsHistory={changeToFlicsHistory}
            onActiveFlics={onActiveFlics}
          />

          <RoccaRequestReceived flicsRequestDetails={changeToFlicsDetails} />

          <RoccaRequestSent roccaSentDetails={changeToRoccaSentDetails} />
        </div>

        {!!amount && !flicsAmount ?
          (
            <div className="button-wrap">
              <CustomButton
                onClick={() => {
                  changeToShareFlics();
                }}
              >
                SHARE
              </CustomButton>
            </div>
          ) : (<></>)
        }

      </div>
    </>
  );
}

export default Flics;
