import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import CustomButton from "../Button/button";

const TransactionFailurePopup = ({ 
  isOpen, 
  onClose, 
  amount,
  transactionType,
  onRefreshBalance 
}) => {
  const handleClose = () => {
    onRefreshBalance && onRefreshBalance();
    onClose();
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '20px',
          padding: '20px',
          textAlign: 'center',
          maxWidth: '400px'
        }
      }}
    >
      <DialogContent>
        <div className="transaction-confirmation-container">
          <div className="transaction-status-icon">
            <div className="status-icon Failed">
              <svg 
                className="error-icon" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="15" y1="9" x2="9" y2="15" />
                <line x1="9" y1="9" x2="15" y2="15" />
              </svg>
            </div>
          </div>
          
          <h2 style={{ 
            fontSize: '20px', 
            color: '#333', 
            marginBottom: '10px' 
          }}>
            Transaction Failed
          </h2>
          
          <p style={{ 
            fontSize: '16px', 
            color: '#6A7587', 
            marginBottom: '20px' 
          }}>
            {transactionType}
          </p>
          
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center'
          }}>
            <CustomButton 
              onClick={handleClose}
              style={{ 
                backgroundColor: '#007bff', 
                color: 'white',
                minWidth: '120px'
              }}
            >
              Close
            </CustomButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionFailurePopup;