import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { flicsABI } from "../contract/flicsABI";
 

import { WHITELIST } from "src/axios/web3/flicsWhitelist";

import { FLICS_ACCEPT_STREAM, FLICS_DELETE_STREAM, FLICS_REJECT_STREAM, FLICS_PULLOUT_STREAM, FLICS_COMPLETE_STREAM } from "src/axios/web3/flicsStream"
// 

import { chains } from "../chain";
import { Interface } from "ethers/lib/utils";
import { toast } from "react-toastify";

export const sendTokensToInitiateFlics = async (authProvider, receiverAddress, flicsAmount) => {
    toast.info("Sending ROCCA Initiate Transaction. Please Wait.")
    const flicsAddress = chains.flicsPoolContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const flicsInstance = new ethers.Contract(
        flicsAddress,
        flicsABI,
        provider
    )
    const flicsInterface = new Interface([
      "event initiated(address _FlicsAddress, address _initiator, address _acceptor, uint256 _amount, uint256 _initiatedAt)"
    ])
    const walletAddress = localStorage.getItem("wallet_address");
    const approvedAmount = ethers.utils.parseEther(flicsAmount.toString());



  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx = await flicsInstance.populateTransaction.initiateFLICS(walletAddress, receiverAddress, approvedAmount);
    const tx1 = {
      to: flicsAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice
    };

    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    if (transactionHash) {
      console.log('Initiate ROCCA Successful');
    }

    const transactionReceipt = await userOpResponse.wait();
    
    if (transactionReceipt.success == "true") {
      const data = transactionReceipt.receipt.logs[10].data;
      const topics = transactionReceipt.receipt.logs[10].topics;

      const decodedLog = flicsInterface.decodeEventLog("initiated", data, topics);

      const _FlicsAddress = decodedLog[0];

      try {
        await WHITELIST("FLICS", _FlicsAddress);
        console.log('ROCCA Whitelist Successful');
      } catch (error) {
        console.log(error);
        console.log('ROCCA Whitelist Unsuccessful');
      }

      try {
        await FLICS_ACCEPT_STREAM(_FlicsAddress);
        await FLICS_DELETE_STREAM(_FlicsAddress);
        await FLICS_REJECT_STREAM(_FlicsAddress);
        await FLICS_PULLOUT_STREAM(_FlicsAddress);
        await FLICS_COMPLETE_STREAM(_FlicsAddress);
        
        console.log('ROCCA Contract Added');
        return true;
      } catch (error) {
        console.log(error);
        console.log('ROCCA Contract Not Added');
        return false;
      }
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);
    return false;
  }
};