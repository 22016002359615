export const roccaWhitelistedMethods = [
    "roccaAccept",
    "roccaReject",
    "roccaPullOut",
    "roccaCompleteOrDelete",
];

export const flicsWhitelistedMethods = [
    "endFLICS",
    "refundBack",
    "signAndAccept",
    "signAndReject",
];