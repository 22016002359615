import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CustomButton from "src/components/Button/button";
import Popup from "src/components/Popup";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import tickCircleIcon from "src/assets/images/tickcircle.svg";
import connectionIcon from "src/assets/images/connectionicon.svg";
import watchIcon from "src/assets/images/watchicon.svg";
import infoIcon from "src/assets/images/informationcircle.svg";
import tickCircle from "src/assets/images/tickcircle.svg";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import ConfettiCelebration from "src/components//ConfettiCelebration/ConfettiCelebration";
import TransactionConfirmationPopup from "../../../components/TransactionConfirmationPopup/TransactionConfirmationPopup";

import "./FlicsDetails.css";

import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

import { sendTokensToTerminateFlics } from "src/web3/FlicsTerminate";

import { claimCashbackFlics } from "src/web3/FlicsCashback";

import { ROCCADelete, ROCCAInitiatorClaimCashback } from "src/web3/ROCCA";

import { getRoccaVersion } from "src/web3/getRoccaVersion";

import { flicsDelete } from "src/axios/POST_API";

import { GET_FLICS_LIST } from "src/axios/GET_API";

import { getRoccaCashbackAmount } from "src/web3/getRoccaCashbackAmount";

const { REACT_APP_CDN_HOST } = process.env;

const FlicsDeployedDetails = ({
  setSliderPage,
  flicsRecipient = {},
  selfProfile,
  flics,
}) => {
  const spinner = useSpinner();

  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const history = useNavigate();

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [flicsAmount, setFlicsAmount] = useState();
  const [cashbackAmount, setCashbackAmount] = useState();
  const [isCelebrationTriggered, setIsCelebrationTriggered] = useState(false);

  const handleAnimationComplete = () => {
    history("/flics");
    setTransactionSuccess(true);
  };

  const [showClaimButton, setShowClaimButton] = useState(false);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [updatedFlicsRecipient, setUpdatedFlicsRecipient] =
    useState(flicsRecipient);

  const {
    did,
    initiated_by,
    accepted_by,
    nick_name,
    profile_pics,
    flics_address,
    amount,
    activated_date,
  } = updatedFlicsRecipient;

  const transactionDescription = `You have successfully claimed ROCCA worth ${
    selfProfile.did === initiated_by ? cashbackAmount : flicsAmount
  } DRPD`;

  const fetchRoccaCashbackAmount = async () => {
    const rewardAmount = await getRoccaCashbackAmount();
    setCashbackAmount(rewardAmount);
  };

  useEffect(() => {
    setFlicsAmount(amount / 1e18);
    fetchRoccaCashbackAmount();
  }, []);

  const selfPic =
    `${REACT_APP_CDN_HOST}` +
    get(selfProfile, "profile_pics[0]", "").replace("//", "/");
  const friendPic =
    `${REACT_APP_CDN_HOST}` + get(profile_pics, "0", "").replace("//", "/");

  // Delete Data
  const {
    delete_sign_1a_by,
    delete_sign_2a_by,
    delete_sign_1a_date,
    delete_sign_2a_date,
  } = updatedFlicsRecipient;
  const {
    delete_sign_1b_by,
    delete_sign_2b_by,
    delete_sign_1b_date,
    delete_sign_2b_date,
  } = updatedFlicsRecipient;
  const { delete_sign_3_by, delete_sign_3_date } = updatedFlicsRecipient;

  // Initialize state for isDeleteActionHappened
  const [isDeleteActionHappened, setIsDeleteActionHappened] = useState(
    delete_sign_1a_by || delete_sign_1b_by
  );

  // Check whether user logged in is A or B?
  let currentUserIsA = false;
  let currentUserIsB = false;
  if (delete_sign_1a_by) {
    if (selfProfile.did === delete_sign_1a_by) {
      currentUserIsA = true;
    } else {
      currentUserIsB = true;
    }
  }

  // Check deletion status and determine labels
  const getDeletionStatus = (deletionBy, deletionDate) => {
    if (deletionBy) {
      return { label: "Deleted by", status: "Completed", deletionDate };
    } else {
      return { label: "Pending from", status: "Pending", deletionDate: null };
    }
  };

  // Determine deletion status for all signatories
  const deletionStatus1a = getDeletionStatus(
    delete_sign_1a_by,
    delete_sign_1a_date
  );
  const deletionStatus2a = getDeletionStatus(
    delete_sign_2a_by,
    delete_sign_2a_date
  );
  const deletionStatus1b = getDeletionStatus(
    delete_sign_1b_by,
    delete_sign_1b_date
  );
  const deletionStatus2b = getDeletionStatus(
    delete_sign_2b_by,
    delete_sign_2b_date
  );

  const [deletionStatus3, setDeletionStatus3] = useState(
    getDeletionStatus(delete_sign_3_by, delete_sign_3_date)
  );

  const handleDeleteAction = () => {
    // Validate required signatures before proceeding
    const validateSignatures = () => {
      if (currentUserIsA) {
        // For user A, both 1a and 2a signatures must exist
        if (!delete_sign_2a_by) {
          alert("Secondary signature is required before final deletion.");
          spinner.setLoadingState(false);
          return false;
        }
      } else if (currentUserIsB) {
        // For user B, both 1b and 2b signatures must exist
        if (!delete_sign_2b_by) {
          alert("Secondary signature is required before final deletion.");
          spinner.setLoadingState(false);
          return false;
        }
      }
      return true;
    };

    spinner.setLoadingState(true);

    try {
      if (currentUserIsA) {
        if (deletionStatus1a.status == "Pending") {
          handleDeleteFlics(1, did);
        } else if (deletionStatus2a.status == "Pending") {
          handleDeleteFlics(2, did);
        } else {
          // Validate before final termination
          if (!validateSignatures()) {
            return;
          }

          handleFlicsTerminate();
          alert("ROCCA 3 deleted successfully.");
          setShouldShowPopup(false);
        }
      } else if (currentUserIsB) {
        if (deletionStatus1b.status == "Pending") {
          handleDeleteFlics(1, did);
        } else if (deletionStatus2b.status == "Pending") {
          handleDeleteFlics(2, did);
        } else {
          // Validate before final termination
          if (!validateSignatures()) {
            return;
          }
          handleFlicsTerminate();
          alert("ROCCA Termination successfully.");
          const currentDate = new Date();
          const newDeletionStatus3 = getDeletionStatus(
            selfProfile.did,
            currentDate
          );
          setDeletionStatus3(newDeletionStatus3);
          setShouldShowPopup(false);
        }
      } else {
        handleDeleteFlics(1, did);
      }
    } catch (error) {
      console.error("Error in terminate action:", error);
      alert("An error occurred during termination. Please try again.");
      spinner.setLoadingState(false);
    } finally {
      // Ensure spinner is turned off in case of early returns
      spinner.setLoadingState(false);
    }
  };

  const handleDeleteFlics = async (deleteType, didValue) => {
    try {
      const response = await flicsDelete(deleteType, didValue);

      console.log("response 127", response);
      if (response.status === "error") {
        // Handle specific error response from API
        alert(`Error: ${response.message}`);
        console.error(`Error deleting ROCCA ${deleteType}:`, response.message);
        spinner.setLoadingState(false);
        return;
      }

      alert(`Flics ${deleteType} deleted successfully.`);

      spinner.setLoadingState(false);
      setShouldShowPopup(false);
      setIsDeleteActionHappened(true);

      // Fetch updated data from the API
      const updatedFlicsList = await GET_FLICS_LIST();
      const updatedRecipient = updatedFlicsList[0];
      setUpdatedFlicsRecipient(updatedRecipient);
    } catch (error) {
      console.error(`Error deleting ROCCA ${deleteType}:`, error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  const handleFlicsTerminate = async () => {
    spinner.setLoadingState(true);
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const roccaVersion = await getRoccaVersion(flics_address);

      // Call the function to send tokens to terminate FLICS
      const flicsTerminateStatus = roccaVersion
        ? await ROCCADelete(authProvider, flics_address)
        : await sendTokensToTerminateFlics(authProvider, flics_address);
      if (flicsTerminateStatus) {
        history("/flics");
      }
    } catch (error) {
      console.error("Error initiating ROCCA Delete 3:", error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  const handleClaimCashbackFlics = async () => {
    spinner.setLoadingState(true);
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }
      debugger;
      // Call the function to send tokens to terminate FLICS
      const flicsAddress = await getActiveFlicsAddress();
      const roccaVersion = await getRoccaVersion(flicsAddress);

      const txSuccess = roccaVersion
        ? await ROCCAInitiatorClaimCashback(
            authProvider,
            selfProfile.wallet_address,
            flicsAddress
          )
        : await claimCashbackFlics(
            authProvider,
            selfProfile.wallet_address,
            flicsAddress
          );

      if (txSuccess) {
        setIsCelebrationTriggered(true);
        setShowConfirmationPopup(true);
      }
    } catch (error) {
      console.error("Error initiating ROCCA Delete 3:", error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  const getActiveFlicsAddress = async () => {
    try {
      const flicsList = await GET_FLICS_LIST();
      const flicsAddress = flicsList[0].flics_address;
      return flicsAddress;
    } catch (error) {
      console.error("Error initiating ROCCA Delete 3:", error);
      return "";
    }
  };

  const handleClick = () => {
    setShouldShowPopup(true);
  };

  const timePeriod = 180 * 24 * 60 * 60 * 1000; // 180 days in milliseconds
  // const timePeriod = 18 * 60 * 1000; // 18 minutes in milliseconds (Testing)

  // Parse the activated_date as a UTC date
  const activationDate = new Date(`${activated_date}Z`);
  // Calculate end date by adding timePeriod to the activation date
  const endDate = new Date(activationDate.getTime() + timePeriod);

  // Calculate remaining days.
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - activationDate.getTime();
  const remainingDays = Math.floor(
    (180 * 24 * 60 * 60 * 1000 - differenceInTime) / (24 * 60 * 60 * 1000)
  );

  const formatDateTimeUTC = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const getFormattedDateTimeUTC = () => {
    const now = new Date();
    return formatDateTimeUTC(now);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();

      const nowUTC = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        )
      );

      if (nowUTC >= endDate) {
        setShowClaimButton(true);
      } else {
        setShowClaimButton(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);

  // Props: Handles dynamic data for rendering user information
  const RenderUserA = ({ nickName }) => (
    <>
      <p>1st Confirmation</p>
      <div>
        <div
          className={`rounded-label ${
            deletionStatus1a.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus1a.label}</div>
          <div className="user">You</div>
        </div>
        <div
          className={`rounded-label ${
            deletionStatus1b.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus1b.label}</div>
          <div className="user">{nickName}</div>
        </div>
      </div>

      <p>2nd Confirmation (after 24 hrs)</p>
      <div>
        <div
          className={`rounded-label active-action ${
            deletionStatus2a.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus2a.label}</div>
          <div className="user">You</div>
        </div>
        <div
          className={`rounded-label ${
            deletionStatus2b.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus2b.label}</div>
          <div className="user">{nickName}</div>
        </div>
      </div>
    </>
  );

  const RenderUserB = ({ nickName }) => (
    <>
      <p>1st Confirmation</p>
      <div>
        <div
          className={`rounded-label ${
            deletionStatus1b.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus1b.label}</div>
          <div className="user">You</div>
        </div>
        <div
          className={`rounded-label ${
            deletionStatus1a.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus1a.label}</div>
          <div className="user">{nickName}</div>
        </div>
      </div>

      <p>2nd Confirmation (after 24 hrs)</p>
      <div>
        <div
          className={`rounded-label ${
            deletionStatus2b.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus2b.label}</div>
          <div className="user">You</div>
        </div>
        <div
          className={`rounded-label ${
            deletionStatus2a.status === "Completed" ? "deleted" : ""
          }`}
        >
          <div className="status">{deletionStatus2a.label}</div>
          <div className="user">{nickName}</div>
        </div>
      </div>
    </>
  );

  return (
    <div className="inner-pages-container">
      <div className="half-pnk">
        {/*<div className="inner-pages-container-wrap">*/}
        {/* <h1 className="page-title">SHARED/DEPLOYED FLICS</h1> */}
        <h1 className="page-title">Deployed ROCCA</h1>
        {/*</div>*/}
      </div>
      <div className="inner-pages-container-wrap">
        <div className="flics-popup-conainer">
          <div className="row justify-content-center">
            <div className="row flics-header mb-3">
              <div className="col-md-5">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={selfPic}
                  className="profile-circle"
                />
                <div className="row">
                  <div className="col-md-12 text-center">
                    <span>You</span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 mb-2">
                <img src={connectionIcon} />
              </div>
              <div className="col-md-5">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={friendPic}
                  className="profile-circle"
                />
                <div className="row">
                  <div className="col-md-12 text-center">
                    <span> {nick_name} </span>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col md={4}>
                <div className="flics-detail-left mb-2">ROCCA ID:</div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right mb-2">
                  <b>{flics_address}</b>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <div className="flics-detail-left mb-2">ROCCA Amount:</div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right mb-2">
                  <img src={dropdTokanIcon} />
                  <b className="ml-2 flics-amount">{amount / 1e18}</b>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <div className="flics-detail-left">Activated on:</div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right">
                  <b>{activated_date}</b>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <div className="flics-detail-left">Ends on:</div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right">
                  <b>{formatDateTimeUTC(endDate)}</b>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <div className="flics-detail-left">
                  <img src={watchIcon} />
                </div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right">
                  <b>{remainingDays}/ 180 Days</b>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <div className="flics-detail-left">Status</div>
              </Col>
              <Col md={8}>
                <div className="flics-detail-right">
                  <b>Deployed</b>
                </div>
              </Col>
            </Row>

            {isDeleteActionHappened == null && (
              <>
                <Row className="mt-3 mb-2">
                  <Col
                    className="col-5 rounded-3 p-2"
                    style={{ border: "1px solid #00FFA3" }}
                  >
                    <div>
                      Signed by <img src={tickCircleIcon}></img>
                    </div>
                    <div>you</div>
                  </Col>
                  <Col className="col-2" />
                  <Col className="col-md-5 border rounded-3 p-2">
                    <div>
                      Signed by <img src={tickCircleIcon} />
                    </div>
                    <div>{nick_name}</div>
                  </Col>
                </Row>
              </>
            )}
          </div>

          {isDeleteActionHappened && (
            <>
              <div className="flics-delete-status-wrap">
                {currentUserIsA && <RenderUserA nickName={nick_name} />}
                {currentUserIsB && <RenderUserB nickName={nick_name} />}

                <p>3rd Confirmation (after 48 hrs)</p>
                <div>
                  <div className="rounded-label">
                    <div className="status">
                      {currentUserIsA
                        ? deletionStatus3.label
                        : currentUserIsB
                        ? "Pending"
                        : "Pending"}
                    </div>
                    <div className="user">you</div>
                    {/*<div className="icon"><img src={infoIcon}></img></div>*/}
                  </div>

                  <div className="rounded-label">
                    <div className="status">
                      {currentUserIsB
                        ? deletionStatus3.label
                        : currentUserIsA
                        ? "Pending"
                        : "Pending"}
                    </div>
                    <div className="user">{nick_name}</div>
                    {/*<div className="icon"><img src={infoIcon}></img></div>*/}
                  </div>
                </div>
              </div>
            </>
          )}

          {showClaimButton ? (
            <div className="flics-delete-action-wrap">
              <div className="button-wrap">
                {selfProfile.did === initiated_by && (
                  <CustomButton
                    onClick={handleClaimCashbackFlics}
                    style={{ marginBottom: "10px" }}
                  >
                    Claim 100% Cashback
                  </CustomButton>
                )}

                {selfProfile.did === accepted_by && (
                  <CustomButton
                    onClick={handleFlicsTerminate}
                    style={{ marginBottom: "10px" }}
                  >
                    Claim ROCCA reward
                  </CustomButton>
                )}
              </div>
            </div>
          ) : (
            <div className="flics-delete-action-wrap">
              <div className="button-wrap">
                <CustomButton
                  onClick={handleClick}
                  style={{ marginBottom: "10px" }}
                >
                  Delete ROCCA
                </CustomButton>
              </div>
            </div>
          )}

          {/* Showing Disabled Buttons*/}
          {!showClaimButton && (
            <div className="flics-tabs-container deployed-flics-action">
              <div className="flics-options">
                {selfProfile.did === initiated_by && (
                  <button style={{ marginBottom: "10px" }} disabled>
                    <span>Claim 100% Cashback</span>
                  </button>
                )}

                {selfProfile.did === accepted_by && (
                  <button style={{ marginBottom: "10px" }} disabled>
                    <span>Claim ROCCA reward</span>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {shouldShowPopup && (
        <Popup
          message={"Are you sure you want to delete this ROCCA?"}
          customAction={handleDeleteAction}
          cancelAction={() => setShouldShowPopup(false)}
        ></Popup>
      )}

      {transactionSuccess && (
        <TransactionConfirmationPopup
          isOpen={showConfirmationPopup}
          onClose={() => {
            setShowConfirmationPopup(false);
            setSliderPage("flicsmain");
          }}
          amount={flicsAmount}
          transactionType={transactionDescription}
        />
      )}

      <ConfettiCelebration
        trigger={isCelebrationTriggered}
        onAnimationComplete={handleAnimationComplete}
      />
    </div>
  );
};

FlicsDeployedDetails.prototype = {
  amount: PropTypes.string,
};

export default FlicsDeployedDetails;
