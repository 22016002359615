export const roccaContractABI = [
  {
    inputs: [
      { internalType: "address", name: "_DROPD", type: "address" },
      { internalType: "address", name: "_revenuePool", type: "address" },
      { internalType: "address", name: "_initiator", type: "address" },
      { internalType: "address", name: "_acceptor", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_acceptedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaAccepted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_terminatedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaCompleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_terminatedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "string",
        name: "typeOfDelete",
        type: "string",
      },
    ],
    name: "OnRoccaDeleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_initiatedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaDeployed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_refundedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaPullOutRefunded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_terminatedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaReceiverRewardClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_refundedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaRejectRefunded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_acceptor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_roccaAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_rejectedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ROCCA.Status",
        name: "_roccaStatus",
        type: "uint8",
      },
    ],
    name: "OnRoccaRejected",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    inputs: [],
    name: "ACCEPTOR_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DROPD",
    outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EITHER_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIATOR_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptedAt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptor",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "contractVersion",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getStatus",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initiatedAt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initiator",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revenuePool",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaAccept",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaAmount",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaCompleteOrDelete",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaPullOut",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaReject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "roccaStatus",
    outputs: [{ internalType: "enum ROCCA.Status", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
];
