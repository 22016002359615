import axios from "axios";
import { ethers } from "ethers";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import RetrieveToken from "src/utils/HelperFunctions/RetrieveToken";

import { logError } from "../sentryLogger";

const { REACT_APP_API_HOST, REACT_APP_WEB3_HOST } = process.env;
// // const { REACT_APP_WEB3_HOST } = process.env;
// const REACT_APP_WEB3_HOST = "https://web3.staging.dropd.network"
//send otp to mobile
export const SEND_OTP_MOBILE = async ({ Phone }) => {
  const response = await axios.post(`${REACT_APP_API_HOST}/v1/signup/`, {
    phone: Phone,
  });
  return response
};

//signup with otp verification mobile
export const SIGNUP_POST = async ({ Phone, otp }) => {
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/signup-verification/`,
    {
      phone: Phone,
      otp: otp,
    }
  );
  localStorage.setItem("token", `${response.data.item}`);
  return response;
};
//mobile login
export const LOGIN = async ({ phone, email }) => {
  const response = await axios.post(`${REACT_APP_API_HOST}/v1/login/`, {
    email,
    phone,
  });
  return response;
};

export const verifyUserAfterBiconomyLogin = async ({ email, wallet_address, login_method }) => {
  try {
    const response = await axios.post(`${REACT_APP_API_HOST}/v1/user-login/`, {
      email,
      wallet_address,
      login_method
    });
    return response;
  } catch (error) {
    
    // Error Log
    logError(error);

    // Handle different error scenarios
    if (error.response) {
      // Server responded with a status code outside the 2xx range
      if (error.response.status === 500) {
        toast.error("Server error. Please try again later.");
      } else if (error.response.status === 404) {
        toast.error("User not found. Please check your details.");
      } else {
        
        // Get the error message and login method from response if available
        if (error.response.data && error.response.data.message) {
          const errorMsg = error.response.data.message;
          const oldLoginMethod = error.response.data.old_login_method;
          
          if (oldLoginMethod) {
            toast.error(`${errorMsg} You previously used ${oldLoginMethod} to login.`);
          } else {
            toast.error(errorMsg);
          }
        } else {
          toast.error("Something went wrong. Please try again.");
        }         
      }
    } else if (error.request) {
      // No response was received from the server
      toast.error("No response from the server. Please check your network connection.");
    } else {
      // Error occurred during request setup
      toast.error("Request failed. Please try again.");
    }
    return error;
  }
};

 
export const registerPhone = async ({ phone }) => {
  const response = await axios.post(`${REACT_APP_API_HOST}/v1/register-phone/`, {
    phone,
  },
    {
      headers: {
        Authorization: `Token ${RetrieveToken()}`,
      },
    }
  );
  return response;
};

export const verifyPhone = async ({ phone, otp }) => {
  const response = await axios.post(`${REACT_APP_API_HOST}/v1/phone-verification/`, {
    phone,
    otp,
  },
    {
      headers: {
        Authorization: `Token ${RetrieveToken()}`,
      },
    }
  );
  return response;
};

// mobile login verification
export const LOGIN_VERIFICATION = async ({ Phone, otp, Email }) => {
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/login-otp-verification/`,
    {
      email: Email,
      phone: Phone,
      otp: otp,
    }
  );

  return response;
};
//send otp to email
export const SEND_OTP_EMAIL = async ({ Email }) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/register-email/`,
    {
      email: Email,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};
//verify otp email
export const EMAIL_OTP_VERIFICATION = async ({ email, otp }) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/email-verification/`,
    {
      email: email,
      otp: otp,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};
//update nickname in user details section
export const UPDATE_NICKNAME = async ({ nickname }) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/update-nickname/`,
    {
      nick_name: nickname,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

//update profile pics in userdetails

export const UPDATE_PROFILE_IMAGES = async (image_files) => {
  let item = RetrieveToken()

  let formData = new FormData();
  let i = 1;
  image_files.forEach(
    f => formData.append("file" + i++, f, f.name)
  );
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/update-profile-images/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
        "Content-Type": "multipart/form-data",
      }
    }
  )
  return (response)
}

//update profile userdetails section

export const UPDATE_PROFILE = async (profile_key, profile_value) => {
  let item = RetrieveToken()

  let formData = new FormData();
  if (Array.isArray(profile_value)) {
    profile_value.forEach((e) => formData.append(profile_key, e));
  } else {
    formData.append(profile_key, profile_value);
  }
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/update-profile/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

export const UPDATE_PERSONAL_PROFILE = async (param) => {

  let item = RetrieveToken()

  let formData = new FormData();
  if (typeof param == "object") {
    Object.keys(param).forEach(function (key) {
      formData.append(key, param[key])
    });
  } else {
    return false;
  }

  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/update-profile/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      }
    }
  )
  return (response)
}

//update post in timeline section
export const CREATE_POST = async (images, text, clubID, pageID) => {
  let item = RetrieveToken()
  let formData = new FormData();
  formData.append("text", text);
  if (images) {
    Array.from(images).forEach((f) => formData.append("images", f, f.name));
  }
  if (clubID !== false) {
    formData.append("club_id", clubID);
  } else if (pageID !== false) {
    formData.append("page_id", pageID);
  }
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/posts/create-post/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

//like post
export const LIKE_POST = async (pid) => {
  let item = RetrieveToken()
  let formData = new FormData();
  formData.append("post_id", pid);

  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/posts/like-post/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  try {
    if (response?.data?.status === "success") {
      return true;
    } else {
      return response?.message;
    }
  } catch (error) {
    return "Something went wrong, try again after some time";
  }
};

//comment api
export const COMMENT_POST = async (pid, comment) => {
  let item = RetrieveToken()
  let formData = new FormData();
  formData.append("post_id", pid);
  formData.append("comment", comment);

  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/posts/comment-post/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  try {
    if (response?.data?.status === "success") {
      return true;
    } else {
      return response?.message;
    }
  } catch (error) {
    return "Something went wrong, try again after some time";
  }
};

//Like profile api
export const LIKE_PROFILE = async (did) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/like-person/`,
    {
      did: did,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

//Friend request api
export const FRIEND_REQUEST = async (did, add_friend) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/friend-request/`,
    {
      did: did,
      send_friend_request: add_friend,
      transaction_id: "0x0"
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

//User post list api
export const USER_POST_LIST = async (did) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/posts/user-posts/`,
    {
      did: did,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};
//Create Club API
export const CREATE_CLUB = async (
  did,
  clubname,
  description,
  profileImage,
  coverImage,
  interests, 
  country, 
  state, 
  city,
  locality,
  joining_fees
) => {
  let authToken = RetrieveToken()
  let formData = new FormData();
  formData.append("club_name", clubname);
  formData.append("description", description);
  formData.append("profile_image", profileImage);
  formData.append("cover_image", coverImage);
  formData.append("category", interests);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("city", city);
  formData.append("locality", locality);
  formData.append("joining_fees", joining_fees);
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/create-club/`,
    formData,
    {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    }
  );
  // const receipt = await axios.post(
  //   `${REACT_APP_WEB3_HOST}/interactions/create-club`,
  //   {
  //     emailId: did,
  //     clubId: response.data.cid
  //   }
  // );
  return response;
};


//Create page api
export const CREATE_PAGE = async (
  pagename,
  description,
  profileImage,
  coverImage
) => {
  let item = RetrieveToken()
  let formData = new FormData();
  formData.append("page_name", pagename);
  formData.append("description", description);
  formData.append("profile_image", profileImage);
  formData.append("cover_image", coverImage);

  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/create-page/`,
    formData,
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

//Join club api
export const USER_JOIN_CLUB = async (did, cid) => {
  let item = RetrieveToken()
  const receipt = await axios.post(
    `${REACT_APP_WEB3_HOST}/interactions/create-club`,
    {
      emailId: did,
      clubId: cid
    }
  );
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/join-club/`,
    {
      club_id: cid,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};

export const ACCEPT_FRIEND_REQUEST = async (did) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/friend-request-accept/`,
    {
      did: did
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};


export const MANAGED_SEND_GIFT = async (did, to) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/sendGift`,
      {
        emailId: did,
        to,
      }
    );
    return response;
  } catch (err) {
    throw err
    throw err
  }
};

export const MANAGED_CLAIM_GIFT = async (did, from) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/claimGift`,
      {
        emailId: did,
        from,
      }
    );
    return response;
  } catch (err) {
    throw err
  }
};
export const MANAGED_REJECT_REQUEST = async (did, from) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/rejectGift`,
      {
        emailId: did,
        from,
      }
    );
    return response;
  } catch (err) {
    alert("couldn't reject request, try again")
    throw err
  }
};
export const MANAGED_PAY_SWIPES = async (did, from) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/lock`,
      {
        emailId: did,
      }
    );
    return response;
  } catch (err) {
    alert("couldn't pay for swipes, try again")
    throw err
  }
};
export const RECORD_SWIPE = async (did, from) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/account/swipe`,
      {
        emailId: did,
      }
    );
    return response;
  } catch (err) {
    throw err
  }
};
export const MANAGED_CANCEL_REQUEST = async (did, to) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/cancelGift`,
      {
        emailId: did,
        to,
      }
    );
    return response;
  } catch (err) {
    throw err
  }
};

export const MANAGED_CANCEL_GIFT = async (did, from) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/cancelGift`,
      {
        emailId: did,
        from,
      }
    );
    return response;
  } catch (err) {
    throw err
  }
};
export const GET_ACCOUNT = async (did, onError) => {
  try {
    const response = await axios.get(
      `${REACT_APP_WEB3_HOST}/wallet/address?emailId=${did}`
    );
    return response.data.address;
  } catch (err) {
    onError(err.response.data.message);
  }
};
export const CREATE_ACCOUNT = async (did, onSuccess) => {
  try {
    const response = await axios.post(`${REACT_APP_WEB3_HOST}/wallet/create`, {
      emailId: did,
    });
    onSuccess(response.data.data.address);
  } catch (err) {
    console.log("error", err.status);
  }
};

export const CLAIM_DRPD = async (did, onSuccess) => {
  try {
    const response = await axios.post(
      `${REACT_APP_WEB3_HOST}/interactions/claim`,
      {
        emailId: did,
      }
    );
    onSuccess(response);
  } catch (err) {
    console.log("error", err.status);
  }
};
export const PAY_SUBSCRIPTION = async (did, onSuccess) => {
  try {
    const response = await axios.post(`${REACT_APP_WEB3_HOST}/interactions/paySubscription`, {
      emailId: did,
    });
    onSuccess(response);
  } catch (err) {
    console.log("error", err.status);
  }
};

export const REJECT_FRIEND_REQUEST = async (did) => {
  let item = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/friend-request-reject/`,
    {
      did: did,
    },
    {
      headers: {
        Authorization: `Token ${item}`,
      },
    }
  );
  return response;
};


//get sent request list
export const getBalance = async (did) => {
  let authToken = RetrieveToken();
  let balance;
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/wallet-balance/`,
    {},
    {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    }
  ); 

  if (response.data['data'][0].balance) {
     balance = response.data['data'][0].balance; 

     balance = ethers.utils.formatEther(balance);

  }else{
    balance = 0;
  }
  
  return balance;
}

export const postFlics = async ({
  amount,
  flicsRecipient
}) => {
  let authToken = RetrieveToken()
  const { status } = await axios.post(
    `${REACT_APP_API_HOST}/v1/flics-request/`,
    {
      did: flicsRecipient?.did,
      amount,
    },
    {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    }
  );
  return Promise.resolve(status);
}

export const getFlicsSent = async () => {
  let authToken = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/flics-request-sent-list/`,
    {},
    {
      headers: {
        Authorization: `Token ${authToken}`,
      }
    },
  );

  return response?.data?.flics_requests_sent ?? [];
}

export const getFlicsRequest = async () => {
  let authToken = RetrieveToken()
  const response = await axios.post(
    `${REACT_APP_API_HOST}/v1/flics-request-list/`,
    {},
    {
      headers: {
        Authorization: `Token ${authToken}`,
      }
    },
  );
  return response?.data?.flics_requests ?? [];
}


export const flicsDelete = async (deleteType, didValue) => {
    let authToken = RetrieveToken();
    const response  = await axios.post(
        `${REACT_APP_API_HOST}/v1/flics/delete/${deleteType}/`,
        {
            did: didValue,
        },
        {
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    );
    return response?.data;
};

export const VIEW_MATCH_SWIPE = async (direction, did) => {
    let authToken = RetrieveToken();
    const { status } = await axios.post(
        `${REACT_APP_API_HOST}/v1/view-match/swipe/${direction}/`,
        {
            did: did,
        },
        {
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    );
    return Promise.resolve(status);
};

export const USER_META_FCM_TOKEN = async (fcmToken) => {
    let authToken = RetrieveToken();
    const { status } = await axios.post(
        `${REACT_APP_API_HOST}/v1/user-meta/`,
        {
            key: "fcm_token",
            value: fcmToken,
        },
        {
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    );
    return Promise.resolve(status);
};

export const USER_META_SIGNUP_REFERRAL_CODE = async (referralCode) => {
    try {
        let authToken = RetrieveToken();
        
        const { status } = await axios.post(
            `${REACT_APP_API_HOST}/v1/user-meta/`,
            {
                key: "signup_referral_code",
                value: referralCode,
            },
            {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            }
        );
        
        return Promise.resolve(status);
    } catch (error) {
        console.error("Failed to save signup referral code:", error);
        
        // Return a successful status even if the API call fails
        return Promise.resolve(200);
    }
}

export const NOTIFICATION_READ = async (notification_id) => {
    let authToken = RetrieveToken();
    const { status } = await axios.post(
        `${REACT_APP_API_HOST}/v1/read-notification/`,
        {
            notification_id: notification_id,
        },
        {
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    );
    return Promise.resolve(status);
};

export const MY_INCOME = async (current_month) => {
    let authToken = RetrieveToken();
    const response = await axios.post(
        `${REACT_APP_API_HOST}/v1/my-income/`,
        {
            current_month: current_month
        },
        {
            headers: {
                Authorization: `Token ${authToken}`,
            },
        }
    );
    return response; // Return the full response object
};

