import { useEffect } from 'react';

const { REACT_APP_GOAFFPRO } = process.env;

const HeadScripts = () => {
  useEffect(() => {
    // GoAffPro Loader Script
    if (!document.getElementById('goaffpro-loader')) {
      const goAffProScript = document.createElement('script');
      goAffProScript.id = 'goaffpro-loader';
      goAffProScript.type = 'text/javascript';
      goAffProScript.src = REACT_APP_GOAFFPRO;
      goAffProScript.async = true;
      document.head.appendChild(goAffProScript);
    }
  }, []);

  return null;
};

export default HeadScripts;