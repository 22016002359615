import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import TextField from "@mui/material/TextField";
import { UPDATE_PERSONAL_PROFILE, USER_META_SIGNUP_REFERRAL_CODE } from "../../../axios/POST_API";
import { Button } from "@mui/material";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import { Country, State, City } from "country-state-city";
import { languageListPrimary, languageListOther } from "../../../utils/Languages/Languages";
import "./knowbetter.css";

function Knowbetter({ setSliderPage, sliderPage, handleProfileUpdate }) {
  const [errors, setErrors] = useState([]);
  const spinner = useSpinner();
  const { t, i18n } = useTranslation();
  const [year, setYear] = useState("");
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [personaldetailsError, setPersonalDetailsError] = useState("");
  const [bio, setBio] = useState("");
  const [referralCode, setReferralCode] = useState("");


  const [languagePreference1, setLanguagePreference1] = useState("");
  const [languagePreference2, setLanguagePreference2] = useState("");
  const [languagePreference3, setLanguagePreference3] = useState("");

  const allowedCountryCodes = [
    "BD", "KH", "IN", "ID", "IR", "LA", "MY", "MM", "PK", "PH", "RU", "SG", "TH", "AE", "VN"
  ];

  const filteredCountries = Country.getAllCountries()
    .filter(country => allowedCountryCodes.includes(country.isoCode))
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setStatesList(State.getStatesOfCountry(country));
  }, [country]);

  useEffect(() => {
    if (state) {
      const citiesOfState = City.getCitiesOfState(country, state);
      setCityList(citiesOfState);
    }
  }, [state, country]);

  const handleYOBChange = (event) => {
    setYear(event.target.value);
  };

  const getFilteredLanguages = (excludeLanguages) => {
    return languageListOther.filter(lang => !excludeLanguages.includes(lang.name));
  };

  const handleLanguagePreference1Change = (e) => {
    const selectedLanguage = e.target.value;
    setLanguagePreference1(selectedLanguage);
    setLanguagePreference2("");
    setLanguagePreference3("");
  };

  const handleLanguagePreference2Change = (e) => {
    const selectedLanguage = e.target.value;
    setLanguagePreference2(selectedLanguage);
    setLanguagePreference3("");
  };

  const handleKnowBetter = async () => {
    const newErrors = [];
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100; // Assuming a maximum age of 100
    const maxYear = currentYear - 18; // Minimum age of 18

    if (!fullName) newErrors.push("Please enter your full name");
    
    if (!year || isNaN(parseInt(year)) || parseInt(year) < minYear || parseInt(year) > maxYear) {
      newErrors.push(`Please enter a valid year of birth (between ${minYear} and ${maxYear})`);
    }

    if (!country) newErrors.push("Please enter your country");
    if (!state) newErrors.push("Please enter your state/province");
    if (!city) newErrors.push("Please enter your city");
    if (!maritalStatus) newErrors.push("Please enter your marital status");
    if (!languagePreference1) newErrors.push("Please select at least one language preference");
    if (!bio) newErrors.push("Please enter your bio");

    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    try {
      spinner.setLoadingState(true);
      await UPDATE_PERSONAL_PROFILE({
        full_name: fullName,
        age: currentYear - parseInt(year),
        year_of_birth: parseInt(year),
        country,
        state,
        city,
        marital_status: maritalStatus,
        language_preference1: languagePreference1,
        language_preference2: languagePreference2,
        language_preference3: languagePreference3,
        bio
      });

      // User meta Singup Referral code
      if (referralCode && referralCode.trim() !== "") {
        await USER_META_SIGNUP_REFERRAL_CODE(referralCode);
      }

      spinner.setLoadingState(false);
      setSliderPage("claim");
    } catch (error) {
      console.log(error, "Error in details update");
      setErrors(["An error occurred while updating your profile. Please try again."]);
    }
  };


  useEffect(() => {
    // When the signup form loads, check for saved referral code
    const savedRefCode = localStorage.getItem('referralCode');
    if (savedRefCode) {
      // Auto-fill the referral code input
      setReferralCode(savedRefCode);
    }
  }, [sliderPage]);

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap">
        <div className='knowbetter-text-container'>
          <div className='knowbetter-text'>
            Let's Know You Better
          </div>
        </div>
      </div>

      <div className="content-wrap">
        <div className='knowbetter-input-box-wrapper'>
          <div>
            <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>Full Name</div>
            <div style={{ marginBottom: "20px" }}>
              <TextField 
                style={{ backgroundColor: "white", borderRadius: "10px" }}
                id="outlined-basic"
                variant="outlined"
                size='small'
                value={fullName}
                onChange={(e) => { setFullName(e.target.value) }}
                sx={{
                  width: "100%", 
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "none" }
                  }
                }}
              />
            </div>
          </div>
          
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <div>
              <div className='input-support-text'>Year of Birth</div>
              <TextField 
                style={{ backgroundColor: "white", borderRadius: "10px" }}
                id="outlined-basic"
                variant="outlined"
                size='small'
                value={year}
                onChange={handleYOBChange}
                sx={{
                  width: "190px", 
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "none" }
                  }
                }}
              />
            </div>
            <div>
              <div className='input-support-text'>Age</div>
              <TextField 
                style={{ backgroundColor: "white", borderRadius: "10px" }}
                id="outlined-basic"
                variant="outlined"
                size='small'
                value={year && !isNaN(parseInt(year)) ? new Date().getFullYear() - parseInt(year) : ""}
                disabled
                sx={{
                  width: "140px", 
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "none" }
                  }
                }}
              />
            </div>
          </div>

          <div className='input-support-text'>Marital Status</div>
          <select 
            onChange={(e) => { setMaritalStatus(e.target.value) }} 
            className='marital-status' 
            value={maritalStatus}
          >
            <option value="">Select Marital Status</option>
            <option value="Married">Married</option>
            <option value="Single">Single</option>
            <option value="Open Marriage">Open Marriage</option>
          </select>

          <div className='input-support-text'>Country</div>
          <select 
            onChange={(e) => { setCountry(e.target.value) }} 
            className='marital-status' 
            value={country}
          >
            <option value="">Select Country</option>
            {filteredCountries.map((item, index) => (
              <option key={index} value={item.isoCode}>{item.name}</option>
            ))}
          </select>

          <div className='input-support-text'>State/Province</div>
          <select 
            onChange={(e) => { setState(e.target.value) }} 
            className='marital-status' 
            value={state}
          >
            <option value="">Select State/Province</option>
            {statesList?.map((item, index) => (
              <option key={index} value={item.isoCode}>{item.name}</option>
            ))}
          </select>

          <div className='input-support-text'>City</div>
          <select 
            onChange={(e) => { setCity(e.target.value) }} 
            className='marital-status' 
            value={city}
          >
            <option value="">Select City</option>
            {cityList?.map((item, index) => (
              <option key={index} value={item.name}>{item.name}</option>
            ))}
          </select>

          <div className='input-support-text'>Language Preferences</div>
          <div>
            <select 
              onChange={handleLanguagePreference1Change} 
              className='marital-status' 
              value={languagePreference1}
            >
              <option value="">Select Language 1</option>
              {languageListPrimary.map((item, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
          <div>
            <select 
              onChange={handleLanguagePreference2Change} 
              className='marital-status' 
              value={languagePreference2}
            >
              <option value="">Select Language 2</option>
              {getFilteredLanguages([languagePreference1]).map((item, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
          <div>
            <select 
              onChange={(e) => setLanguagePreference3(e.target.value)} 
              className='marital-status' 
              value={languagePreference3}
            >
              <option value="">Select Language 3</option>
              {getFilteredLanguages([languagePreference1, languagePreference2]).map((item, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className='input-support-text'>Your bio</div>
          <div>
            <TextField 
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              id="outlined-basic"
              variant="outlined"
              size='small'
              multiline
              rows={4}
              value={bio}
              onChange={(e) => { setBio(e.target.value) }}
              sx={{
                width: "100%", 
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { border: "none" }
                }
              }}
            />
          </div>

          <div className="referral-code-wrap">
            <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>Referral Code</div>
            <div>
              <TextField 
                style={{ backgroundColor: "white", borderRadius: "10px" }}
                id="outlined-basic"
                variant="outlined"
                size='small'
                value={referralCode}
                onChange={(e) => { setReferralCode(e.target.value) }}
                sx={{
                  width: "100%", 
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "none" }
                  }
                }}
              />
            </div>
          </div>
        </div>
        
        {errors.length > 0 && (
          <div className="error-list">
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="button-wrap">
        <Button 
          onClick={handleKnowBetter} 
          variant='contained' 
          style={{ 
            width: "100%", 
            color: "white", 
            backgroundColor: "#C387C3", 
            borderRadius: "12px", 
            marginTop: "10px", 
            marginBottom: "10px", 
            boxShadow: "none" 
          }}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
}

export default Knowbetter;