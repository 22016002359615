// Utility function to convert month digit to month name
export const getMonthName = (monthDigit) => {
  // Handle various input types
  const digit = parseInt(monthDigit, 10);
  
  // Validate input
  if (isNaN(digit) || digit < 1 || digit > 12) {
    return 'Invalid Month';
  }
  
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  // Arrays are zero-indexed, so subtract 1 from the month digit
  return months[digit - 1];
};

// Optional: Get abbreviated month name (Jan, Feb, etc.)
export const getMonthNameShort = (monthDigit) => {
  const fullName = getMonthName(monthDigit);
  return fullName === 'Invalid Month' ? fullName : fullName.substring(0, 3);
};

// Optional: Get current month as digit (1-12)
export const getCurrentMonthDigit = () => {
  return new Date().getMonth() + 1; // getMonth() returns 0-11
};