import { chains } from "../chain";
import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";

export const subscriptionApproval = async (authProvider) => {
  try {
    const contractAddress = chains.dropdContractAdd;
    const subscriptionAddress = chains.subscriptionAdd;
    const approvedSubscriptionAmount = ethers.utils.parseEther("1000000000");
    const provider = new ethers.providers.JsonRpcProvider(
      chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx1 = await contractInstance.populateTransaction.approve(subscriptionAddress, approvedSubscriptionAmount);
    const tx1 = {
      to: contractAddress,
      data: minTx1.data,
      gasPrice: increasedGasPrice
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: {nonceKey: i++ }
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();
    if (transactionReceipt.success == "true") {
      return true;
    } else {
        return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};