import { ethers } from "ethers";
import { roccaContractABI } from "../contract/roccaContractABI";

import { chains } from "../chain";

export const getRoccaStatus = async (roccaAddress) => {
    debugger
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );

    const contractInstance = new ethers.Contract(
        roccaAddress,
        roccaContractABI,
        provider
    );

    try {
        const tx = await contractInstance.roccaStatus();
        return tx;
        //@ts-ignore
    } catch (error) {
        console.log(error);
        console.log('Fetch Status Unsuccessful');
    }
};
