import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";

import { chains } from "../chain";

export const getApproval = async () => {
    const contractAddress = chains.dropdContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );

    const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
    );

    const walletAddress = localStorage.getItem("wallet_address");
    const subscriptionAddress = chains.subscriptionAdd;

    try {
        const tx = await contractInstance.allowance(walletAddress, subscriptionAddress);
        
        if (tx) {
            const activeAmount = parseInt(tx)
			const smallAmount = (activeAmount / 1e18);
			return smallAmount;
        }
        //@ts-ignore
    } catch (error) {
        console.log(error);
    }
};
