import axios from "axios";
const {
  REACT_APP_MORALIS_API_KEY,
  REACT_APP_MORALIS_ACCEPT_STREAM,
  REACT_APP_MORALIS_REJECT_STREAM,
  REACT_APP_MORALIS_DELETE_STREAM,
  REACT_APP_MORALIS_COMPLETE_STREAM,
  REACT_APP_MORALIS_PULLOUT_STREAM,
  REACT_APP_MORALIS_ACCEPT_STREAM_LEGACY,
  REACT_APP_MORALIS_REJECT_STREAM_LEGACY,
  REACT_APP_MORALIS_TERMINATE_STREAM_LEGACY,
  REACT_APP_MORALIS_COMPLETE_STREAM_LEGACY,
  REACT_APP_MORALIS_REFUND_STREAM_LEGACY,
  REACT_APP_LEGACY
} = process.env;

export const FLICS_ACCEPT_STREAM = async (contractAddress) => {

  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };

    const body = {
      address: contractAddress,
    };
    
    const stream = REACT_APP_LEGACY == "true" ? REACT_APP_MORALIS_ACCEPT_STREAM_LEGACY : REACT_APP_MORALIS_ACCEPT_STREAM;

    const response = await axios.post(
      stream,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_DELETE_STREAM = async (contractAddress) => {
  debugger;
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };

    const body = {
      address: contractAddress,
    };

    const stream = REACT_APP_LEGACY == "true" ? REACT_APP_MORALIS_TERMINATE_STREAM_LEGACY : REACT_APP_MORALIS_DELETE_STREAM;

    const response = await axios.post(
      stream,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_REJECT_STREAM = async (contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };
    const body = {
      address: contractAddress,
    };

    const stream = REACT_APP_LEGACY == "true" ? REACT_APP_MORALIS_REJECT_STREAM_LEGACY : REACT_APP_MORALIS_REJECT_STREAM;

    const response = await axios.post(
      stream,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_PULLOUT_STREAM = async (contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };
    const body = {
      address: contractAddress,
    };

    const stream = REACT_APP_LEGACY == "true" ? REACT_APP_MORALIS_REFUND_STREAM_LEGACY : REACT_APP_MORALIS_PULLOUT_STREAM;

    const response = await axios.post(
      stream,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_COMPLETE_STREAM = async (contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };
    const body = {
      address: contractAddress,
    };

    const stream = REACT_APP_LEGACY == "true" ? REACT_APP_MORALIS_COMPLETE_STREAM_LEGACY : REACT_APP_MORALIS_COMPLETE_STREAM;

    const response = await axios.post(
      stream,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};
