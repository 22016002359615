import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { flicsABI } from "../contract/flicsABI";
import { roccaContractABI } from "../contract/roccaContractABI";
import { contractABI } from "../contract/contractABI";
import { getRoccaStatus } from "./getRoccaStatus";

import { WHITELIST } from "src/axios/web3/flicsWhitelist";

import {
  FLICS_ACCEPT_STREAM,
  FLICS_DELETE_STREAM,
  FLICS_COMPLETE_STREAM,
  FLICS_REJECT_STREAM,
  FLICS_PULLOUT_STREAM,
} from "src/axios/web3/flicsStream";
//

import { chains } from "../chain";
import { Interface } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { getWalletBalance } from "./getWalletBalance";

export const ROCCAShare = async (
  authProvider,
  receiverAddress,
  flicsAmount
) => {
  debugger
  toast.info("Sending ROCCA Initiate Transaction. Please Wait.");
  const flicsAddress = chains.flicsPoolContractAdd;
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);
  const flicsInstance = new ethers.Contract(flicsAddress, flicsABI, provider);
  const flicsInterface = new Interface([
    "event OnRoccaShared(address _roccaAddress, address _initiator, address _acceptor, uint256 _amount, uint256 _initiatedAt)",
  ]);
  const walletAddress = localStorage.getItem("wallet_address");
  const approvedAmount = ethers.utils.parseEther(flicsAmount.toString());
  debugger
  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx = await flicsInstance.populateTransaction.roccaShare(
      walletAddress,
      receiverAddress,
      approvedAmount
    );
    const tx1 = {
      to: flicsAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice,
    };

    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    if (transactionHash) {
      console.log("Initiate ROCCA Successful");
    }

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      console.log(transactionReceipt.receipt.logs)
      const data = transactionReceipt.receipt.logs[10].data;
      const topics = transactionReceipt.receipt.logs[10].topics;

      const decodedLog = flicsInterface.decodeEventLog(
        "OnRoccaShared",
        data,
        topics
      );
      console.log(decodedLog)
      const _FlicsAddress = decodedLog[0];

      try {
        await WHITELIST("ROCCA", _FlicsAddress);
        console.log("ROCCA Whitelist Successful");
      } catch (error) {
        console.log(error);
        console.log("ROCCA Whitelist Unsuccessful");
      }

      try {
        await FLICS_ACCEPT_STREAM(_FlicsAddress);
        await FLICS_DELETE_STREAM(_FlicsAddress);
        await FLICS_COMPLETE_STREAM(_FlicsAddress);
        await FLICS_REJECT_STREAM(_FlicsAddress);
        await FLICS_PULLOUT_STREAM(_FlicsAddress);

        console.log("ROCCA Contract Added");
        return true;
      } catch (error) {
        console.log(error);
        console.log("ROCCA Contract Not Added");
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCAActivate = async (authProvider, flicsAmount) => {
  toast.info("Sending ROCCA Activate Transaction. Please Wait.");
  const walletBalance = await getWalletBalance();
  if (walletBalance < flicsAmount) {
    toast.warn("Insufficient Wallet Balance");
    return;
  }

  const contractAddress = chains.dropdContractAdd;
  const flicsAddress = chains.flicsPoolContractAdd;
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);
  const contractInstance = new ethers.Contract(
    contractAddress,
    contractABI,
    provider
  );
  const flicsInstance = new ethers.Contract(flicsAddress, flicsABI, provider);
  const walletAddress = localStorage.getItem("wallet_address");
  const approvedAmount = ethers.utils.parseEther(flicsAmount);
  debugger
  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx1 = await contractInstance.populateTransaction.approve(
      flicsAddress,
      approvedAmount
    );

    const tx1 = {
      to: contractAddress,
      data: minTx1.data,
      gasPrice: increasedGasPrice,
    };
    const minTx2 = await flicsInstance.populateTransaction.roccaActivate(
      walletAddress,
      approvedAmount
    );

    const tx2 = {
      to: flicsAddress,
      data: minTx2.data,
      gasPrice: increasedGasPrice,
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction([tx1, tx2], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: { nonceKey: i++ },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCARecallRefund = async (authProvider, initiatorAddress) => {
  toast.info("Sending ROCCA Refund Transaction. Please Wait.")
  const flicsAddress = chains.flicsPoolContractAdd;
  const provider = new ethers.providers.JsonRpcProvider(
      chains.providerUrl
  );
  const flicsInstance = new ethers.Contract(
      flicsAddress,
      flicsABI,
      provider
  )
debugger
try {
  const gasPrice = await provider.getGasPrice();
  const increasedGasPrice = gasPrice.mul(110).div(100);

  const minTx = await flicsInstance.populateTransaction.roccaRecallRefund(initiatorAddress);
  
  const tx1 = {
    to: flicsAddress,
    data: minTx.data,
    gasPrice: increasedGasPrice
  };

  //@ts-ignore
  const userOpResponse = await authProvider?.sendTransaction(tx1, {
    paymasterServiceData: { mode: PaymasterMode.SPONSORED },
  });
  //@ts-ignore
  const { transactionHash } = await userOpResponse.waitForTxHash();
  console.log(transactionHash)

  const transactionReceipt = await userOpResponse.wait();

  if (transactionReceipt.success == "true") {
    return true;
  } else {
    return false;
  }
  

} catch (error) {
  console.log(error);
  return false;
}
};

export const ROCCAInitiatorClaimCashback = async (
  authProvider,
  flicsInitiator,
  flicsAddress
) => {
  debugger;
  toast.info("Sending ROCCA Cashback Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsInstance = new ethers.Contract(
    chains.flicsPoolContractAdd,
    flicsABI,
    provider
  );

  const flicsContractInstance = new ethers.Contract(
    flicsAddress,
    roccaContractABI,
    provider
  );

  const roccaStatus = await getRoccaStatus(flicsAddress);

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx1 =
      await flicsContractInstance.populateTransaction.roccaCompleteOrDelete();

    const tx1 = {
      to: flicsAddress,
      data: minTx1.data,
      gasPrice: increasedGasPrice,
    };

    const minTx2 =
      await flicsInstance.populateTransaction.roccaInitiatorClaimCashback(
        flicsInitiator,
        flicsAddress
      );

    const tx2 = {
      to: chains.flicsPoolContractAdd,
      data: minTx2.data,
      gasPrice: increasedGasPrice,
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse =
      roccaStatus == 4
        ? await authProvider?.sendTransaction(tx2, {
            paymasterServiceData: { mode: PaymasterMode.SPONSORED },
          })
        : await authProvider?.sendTransaction([tx1, tx2], {
            paymasterServiceData: { mode: PaymasterMode.SPONSORED },
            nonceOptions: { nonceKey: i++ },
          });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCAAccept = async (authProvider, flicsContractAddress) => {
  toast.info("Sending ROCCA Accept Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsContractInstance = new ethers.Contract(
    flicsContractAddress,
    roccaContractABI,
    provider
  );

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.roccaAccept();

    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice,
    };

    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    console.log('transactionHash', transactionHash);
    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCAReject = async (authProvider, flicsContractAddress) => {
  toast.info("Sending ROCCA Reject Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsContractInstance = new ethers.Contract(
    flicsContractAddress,
    roccaContractABI,
    provider
  );

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.roccaReject();
    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      nonce: Number(nonce),
      gasPrice: increasedGasPrice,
    };
    debugger
    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCAPullOut = async (authProvider, flicsContractAddress) => {
  toast.info("Sending ROCCA Pull Out Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsContractInstance = new ethers.Contract(
    flicsContractAddress,
    roccaContractABI,
    provider
  );

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.roccaPullOut();
    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      nonce: Number(nonce),
      gasPrice: increasedGasPrice,
    };
    debugger
    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ROCCADelete = async (authProvider, flicsContractAddress) => {
  toast.info("Sending ROCCA Terminate Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsContractInstance = new ethers.Contract(
    flicsContractAddress,
    roccaContractABI,
    provider
  );

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    // const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.roccaCompleteOrDelete();
    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice,
    };
    debugger
    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    console.log(transactionHash)

    const transactionReceipt = await userOpResponse.wait();

    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
