import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';

import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"
import moneyIcon from "src/assets/images/money-white.svg";

import { GET_FLICS_LIST } from "src/axios/GET_API";



const FlicsDisplayBoard = ({
    amount,
    flicsAmount,
    flicsRequestDeployed,
    flicList
}) => {

    const login_did = localStorage.getItem("did");

    return (
      <div className="flics-popup-conainer">
        <div className="flics-tabs-container">
          <div className="flics-options">
            {flicList
              .filter(flics => 
                // Show if active status
                (flics.status === "active" || 
                // OR if initiated by current user AND cashback not claimed
                (parseInt(flics.initiated_by) === parseInt(login_did) && flics.rocca_claimed_cashback === false)) 
                && flics.status !== "terminated" // Exclude terminated status explicitly
              )
              .map((flics, index) => {
                // Calculate remaining days
                const activationDate = new Date(`${flics.activated_date}Z`);
                const currentDate = new Date();
                const differenceInTime = currentDate.getTime() - activationDate.getTime();
                const remainingDays = Math.floor((180 * 24 * 60 * 60 * 1000 - differenceInTime) / (24 * 60 * 60 * 1000));
                
                return (
                  <button 
                    key={index} 
                    onClick={() => flicsRequestDeployed(flics)} 
                    style={{ backgroundColor: '#EC1C80', color: '#ffffff' }}
                  >
                    <span>Deployed <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                      <img src={moneyIcon} alt="Money Icon" /> {flics.amount / 1e18}
                    </span></span>
                    <span>{remainingDays}/{flics.duration}</span>
                  </button>
                );
              })
            }
          </div>
        </div>

        <div className="balance mt-2">
          <span><img src={dropdTokanIcon} /></span>
          <span>{flicsAmount ?? amount ?? 0}</span>
        </div>
        <div className="lock-in">Lock in 00/90 days</div>
      </div>
    );
};

FlicsDisplayBoard.prototype = {
    amount: PropTypes.string,
}

export default FlicsDisplayBoard;