import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material"

// import components
import TimelineNav from "../../components/timelinenav/timelineNav";
import FloatingToolbar from "../../components/FloatingToolbar";

// assets
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import swipwIcon from "src/assets/images/db-swipe-icon.svg";
import transactionIcon from "src/assets/images/db-transaction-icon.svg"
import "./dashboard.css"

function Dashboard() {

  return (
    <div style={{display: "flex", flexDirection: "column",}}>
      <div className="page-page-wrapper flics-page-container">
        <div className="pnkbg">
          <TimelineNav />
        </div>

        <div>
          <div className="inner-pages-container">
            <div className="dashboard-content">
              <div className="half-pnk">
                  <div className="wallet-title-menu-wrap">
                    <h1 className="page-title">Dashboard</h1>
                  </div>
              </div>

              <div className="db-section dashboard-users">
                <div className="container">
                  <p className="db-section-title">Users</p>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#FAF3FF"}}>
                        <p className="metric-value"><strong>10</strong> k</p>
                        <p className="metric-title">DRPD Holders</p>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#FAF3FF"}}>
                        <p className="metric-value"><strong>55</strong> k</p>
                        <p className="metric-title">Subscribers</p>
                      </div>
                    </Grid>
                  </Grid>

                  {/*<Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#F6F6F6"}}>
                        <p className="metric-value">21,456</p>
                        <p className="metric-title">Females</p>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#F6F6F6"}}>
                        <p className="metric-value">33,632</p>
                        <p className="metric-title">Males</p>
                      </div>
                    </Grid>
                  </Grid>*/}
                </div>
              </div>

              <div className="container">
                <div className="border-line"></div>
              </div>

              <div className="db-section dashboard-activity">
                <div className="container">
                  <p className="db-section-title">Activity</p>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#FFE4F1"}}>
                        <p className="metric-value">15,456</p>
                        <p className="metric-title">Friend Requests</p>
                      </div>

                      <div className="metric-item" style={{backgroundColor: "#FFE4F1"}}>
                        <p className="metric-value">1,156</p>
                        <p className="metric-title">Clubs</p>
                      </div>

                      <div className="metric-item" style={{backgroundColor: "#FFE4F1"}}>
                        <p className="metric-value">856</p>
                        <p className="metric-title">ROCCA Activated</p>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#E1D7F0"}}>
                        <p className="metric-value">12,456</p>
                        <p className="metric-title">Friend Requests Accepted</p>
                      </div>

                      <div className="metric-item" style={{backgroundColor: "#E1D7F0"}}>
                        <p className="metric-value">27,456</p>
                        <p className="metric-title">Club Join Requests</p>
                      </div>

                      <div className="metric-item" style={{backgroundColor: "#E1D7F0"}}>
                        <p className="metric-value">672</p>
                        <p className="metric-title">ROCCA Deployed</p>
                      </div>
                    </Grid>
                  </Grid>

                

                  <div className="db-swipe-activity metric-item bg-white">
                    <span>
                      <img src={swipwIcon} />
                      Left Swipes
                    </span>
                    <p className="metric-value"><span>33,632</span></p>
                  </div>

                  <div className="db-swipe-activity metric-item bg-white">
                    <span>
                      <img src={swipwIcon} />
                      Right Swipes
                    </span>
                    <p className="metric-value"><span>33,632</span></p>
                  </div>

                  <div className="db-swipe-activity metric-item bg-white">
                    <span>
                      <img src={swipwIcon} />
                      Up Swipes
                    </span>
                    <p className="metric-value"><span>33,632</span></p>
                  </div>

                  <div className="db-swipe-activity metric-item bg-white">
                    <span>
                      <img src={swipwIcon} />
                      Down Swipes
                    </span>
                    <p className="metric-value"><span>33,632</span></p>
                  </div>

                </div>
              </div>
              
              <div className="db-section dashboard-transactions">
                <div className="container">
                  <p className="db-section-title">Transactions</p>

                  <div className="db-total-transactions metric-item bg-white">
                    <span>
                      <img src={transactionIcon} />
                      Total
                    </span>
                    <p className="metric-value"><span>33,632</span></p>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                        <p className="metric-value">21,456</p>
                        <p className="metric-title">Daily Avg.</p>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                        <p className="metric-value">21,456</p>
                        <p className="metric-title">Weekly Avg.</p>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                        <p className="metric-value">33,632</p>
                        <p className="metric-title">Month Avg.</p>
                      </div>
                    </Grid>

                  </Grid>  
                </div>
                
              </div>

              <div className="db-section dashboard-users-revenue">
                <div className="container">
                  <p className="db-section-title">Users’ Revenue</p>

                
                  <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div>
                          <p className="metric-title">Total Likes</p>
                          <p className="metric-value"><span>33,632</span></p>
                        </div>
                        <div className="border-line margin-tb"></div>
                        <div>
                          <p className="metric-title">Last Month</p>
                          <p className="metric-value"><span>1200</span></p>
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <div>
                          <p className="metric-title">Unit Value</p>
                          <p className="metric-value">1.5 <img src={dropdTokanIcon} /></p>  
                        </div>
                        <div className="border-line margin-tb"></div>
                        <div>
                          <p className="metric-title">Last Month</p>
                          <p className="metric-value">672 <img src={dropdTokanIcon} /></p>  
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div>
                          <p className="metric-title">Total Minutes</p>
                          <p className="metric-value"><span>2,983</span></p>
                        </div>
                        <div className="border-line margin-tb"></div>
                        <div>
                          <p className="metric-title">Last Month</p>
                          <p className="metric-value"><span>878</span></p>
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <div>
                          <p className="metric-title">Unit Value</p>
                          <p className="metric-value">1.5 <img src={dropdTokanIcon} /></p>
                        </div>
                        <div className="border-line margin-tb"></div>
                        <div>
                          <p className="metric-title">Last Month</p>
                          <p className="metric-value">436 <img src={dropdTokanIcon} /></p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>


                  <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <p className="metric-title">User Reward Pool</p>
                        <p className="metric-value"><span>2,983</span> <img src={dropdTokanIcon} /></p>
                      </Grid>

                      <Grid item xs={4}>
                        <p className="metric-title">Last Month</p>
                        <p className="metric-value">878 <img src={dropdTokanIcon} /></p>
                      </Grid>
                    </Grid>
                  </div>
                   
                  <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <p className="metric-title">User Claiming Rewards</p>
                        <p className="metric-value"><span>2,983</span> <img src={dropdTokanIcon} /></p>
                      </Grid>

                      <Grid item xs={4}>
                        <p className="metric-title">Last Month</p>
                        <p className="metric-value">878 <img src={dropdTokanIcon} /></p>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                        <p className="metric-value"><span>33,632</span> <img src={dropdTokanIcon} /></p>
                        <p className="metric-title">Token Circulation</p>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="metric-item" style={{backgroundColor: "#FFFFFF"}}>
                        <p className="metric-value">01.23 <img src={dropdTokanIcon} /></p>
                        <p className="metric-title">ROCCA Stake (10%)</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

            </div>
          </div>

          <FloatingToolbar />
        </div>
      </div>


    </div>
  )
}

export default Dashboard;


