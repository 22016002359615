import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children, restrictIfProfileCompleted = false }) => {
  const token = localStorage.getItem('token');
  const profileCompleted = localStorage.getItem("profile_completed") === "true";
  const location = useLocation();

  if (!token) {
    // Redirect to login page and save the attempted URL
    return <Navigate to="/" state={{ returnUrl: location.pathname }} />;
  }

  // Check if this route should be restricted for users with completed profiles
  if (restrictIfProfileCompleted && profileCompleted) {
    // Redirect to dashboard or home page if profile is completed
    return <Navigate to="/timeline" />;
  }

  return children;
};

export default AuthGuard;