import { useEffect } from "react";
import Clarity from '@microsoft/clarity';

const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY;

const ClaritySetup = () => {
  useEffect(() => {
    if (CLARITY_PROJECT_ID) {
      Clarity.init(CLARITY_PROJECT_ID);
    }
  }, []);

  return null;
};

export default ClaritySetup;
