import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackingPixel = () => {
  const location = useLocation();

  function trackGoaffproConversion(user_did) {
    try {
      window.goaffpro_order = {
        number : user_did,
        total: 1000,
        forceSDK:true
      }
      if(typeof window.goaffproTrackConversion !== "undefined"){
        window.goaffproTrackConversion(window.goaffpro_order)
      }
    } catch (error) {
      // Comprehensive error logging
      console.error('Tracking Pixel Error:', {
        message: error.message
      });
    }
  }

  useEffect(() => {
    
    try {
      const did = localStorage.getItem("did");
      const currentPath = location.pathname;
      
      // Remove any existing tracking elements first
      const existingPixel = document.getElementById("affiliate-tracking-pixel");
      const existingConversionScript = document.getElementById("goaffpro-conversion-script");
      
      if (existingPixel) existingPixel.remove();
      if (existingConversionScript) existingConversionScript.remove();
      
      // Check if we're on the right path and have a DID
      if (currentPath === "/userdetails/claimstatus" && did) {

        // Create affilienet START 
        const img = document.createElement("img");
        img.id = "affiliate-tracking-pixel";
        img.src = `https://affilienet.o18.click/p?m=187&t=i&gb=1&adv_sub1=${did}`;
        img.width = 0;
        img.height = 0;
        img.style.display = "none";
        document.body.appendChild(img);
        // Create affilienet END 
        
        // trackGoaffproConversion function call
        trackGoaffproConversion(did);

      }
    } catch (error) {
      console.error('Tracking error', error);
    }
  }, [location.pathname]);
  
  return null;
};

export default TrackingPixel;