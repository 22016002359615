import { ethers } from "ethers";
import { flicsABI } from "../contract/flicsABI";

import { chains } from "../chain";

export const getRoccaCashbackAmount = async () => {
    const roccaPoolAddress = chains.flicsPoolContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );

    const roccaPoolInstance = new ethers.Contract(
        roccaPoolAddress,
        flicsABI,
        provider
    );

    const walletAddress = localStorage.getItem("wallet_address");
    
    try {
        const tx = await roccaPoolInstance.rewardTokens(walletAddress);
        const rewardAmount = parseFloat(tx)
        const smallAmount = (rewardAmount / 1e18);
        return smallAmount.toFixed(2);
    } catch (error) {
        console.log(error);
        console.log('Fetch rocca reward unsuccessful');
        return 0;
    }
};
