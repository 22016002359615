import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TimelineNav from "../../components/timelinenav/timelineNav";
import { fetchHistory } from "../../axios/web3/graphTransactionHistory";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import transOutIcon from "src/assets/images/transouticon.svg";
import transInIcon from "src/assets/images/transinicon.svg";
import "./transactions.css";

import { chains } from "../../chain";

function Transactions({ setSliderPage }) {
  const location = useLocation();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState("All");
  const [transactionTypes, setTransactionTypes] = useState(["All"]);

  useEffect(() => {
    // Get the transaction type from URL parameters if present
    const urlParams = new URLSearchParams(location.search);
    const typeParam = urlParams.get('type');
    
    const fetchTransactions = async () => {
      try {
        const userAddress = localStorage.getItem("wallet_address");
        const response = await fetchHistory(userAddress);
        const data = response.data;

        const claimedUsers = new Set(
          (data.claims || []).map((claim) => claim.user.toLowerCase())
        );
        
        // First collect all non-transfer transactions
        const nonTransferTransactions = [
          ...mapFilteredClaims(data.claims, userAddress),
          ...mapTransactions(data.sentFriendRequests, "Add Friend", "out"),
          ...mapTransactions(data.acceptFriendRequests, "Accept Friend", "in"),
          ...mapTransactions(data.rejectFriendRequests, "Reject Friend", "out"),
          ...mapTransactions(data.cancelFriendRequests, "Cancel Friend", "in"),
          ...mapTransactions(
            data.flicsactivateds,
            "Rocca Activate",
            "out",
            "_amount"
          ),
          ...mapTransactions(
            data.flicsrefundeds,
            "Rocca Recall",
            "in",
            "_amount"
          ),
          ...mapTransactions(
            data.rewardClaimeds,
            "Rocca Cashback",
            "in",
            "_amount"
          ),
          ...mapTransactions(
            data.onRoccaActivateds,
            "Rocca Activate",
            "out",
            "_amount"
          ),
          ...mapTransactions(
            data.onRoccaRecallRefundeds,
            "Rocca Recall",
            "in",
            "_amount"
          ),
          ...mapTransactions(
            data.onRoccaInitiatorCashbackClaimeds,
            "Rocca Cashback",
            "in",
            "_amount"
          ),
          ...mapTransactions(
            data.clubCreateds,
            "Create Club",
            "out",
            "_creationFee"
          ),
          ...mapClubJoinedTransactions(data.clubJoineds, userAddress),
          ...mapTransactions(
            data.viewMatchFees_collection,
            "Viewmatch Fees",
            "out"
          ),
          ...mapTransactions(data.paidSubscriptions, "Subscription", "out"),
          ...mapTransactions(data.claimUserRewards, "Income Claim", "in", "amount"),
        ];

        // Create a set of timestamp-amount pairs for all non-transfer transactions
        // This will be used to identify and filter out transfer events that correspond to other transaction types
        const nonTransferTxSignatures = new Set();
        nonTransferTransactions.forEach(tx => {
          // Use normalized amount (convert to same decimal precision) and timestamp as signature
          const normalizedAmount = (Number(tx.amount) / 1e18).toFixed(6);
          const signature = `${tx.blockTimestamp}-${normalizedAmount}-${tx.direction}`;
          nonTransferTxSignatures.add(signature);
        });

        // Filter transfers to exclude those that match other transaction types
        const filteredTransfers = mapFilteredTransfers(data.transfers, claimedUsers, userAddress)
          .filter(transfer => {
            const normalizedAmount = (Number(transfer.amount) / 1e18).toFixed(6);
            const signature = `${transfer.blockTimestamp}-${normalizedAmount}-${transfer.direction}`;
            return !nonTransferTxSignatures.has(signature);
          });

        // Combine the non-transfer transactions with the filtered transfers
        const allTransactions = [
          ...nonTransferTransactions,
          ...filteredTransfers
        ];

        // Sort by timestamp, most recent first
        allTransactions.sort(
          (a, b) => Number(b.blockTimestamp) - Number(a.blockTimestamp)
        );

        // Extract unique transaction types for the dropdown
        const uniqueTypes = ["All", ...new Set(allTransactions.map(tx => tx.type))];
        
        setTransactionTypes(uniqueTypes);
        setTransactions(allTransactions);
        
        // Set the selected type from URL parameter if it exists and is valid
        if (typeParam && uniqueTypes.includes(typeParam)) {
          setSelectedType(typeParam);
          // Filter transactions based on the URL parameter
          const filtered = allTransactions.filter(tx => tx.type === typeParam);
          setFilteredTransactions(filtered);
        } else {
          setFilteredTransactions(allTransactions);
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching transactions:", err);
        setError("Failed to load transactions. Please try again later.");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [location.search]);

  // Filter transactions when selectedType changes
  useEffect(() => {
    if (selectedType === "All") {
      setFilteredTransactions(transactions);
    } else {
      const filtered = transactions.filter(tx => tx.type === selectedType);
      setFilteredTransactions(filtered);
    }
  }, [selectedType, transactions]);

  const handleFilterChange = (e) => {
    setSelectedType(e.target.value);
  };

  const mapTransactions = (
    transactionArray,
    type,
    direction,
    amountKey = "amount"
  ) => {
    return (transactionArray || []).map((t) => ({
      type,
      direction,
      amount: t[amountKey],
      blockTimestamp: t.blockTimestamp || t.timestamp,
    }));
  };

  const mapClubJoinedTransactions = (clubJoineds, userAddress) => {
    return (clubJoineds || []).map((t) => ({
      type: "Join Club",
      direction:
        t._creator.toLowerCase() === userAddress.toLowerCase() ? "in" : "out",
      amount: t._joiningFee,
      blockTimestamp: t.blockTimestamp,
    }));
  };

  const mapFilteredTransfers = (transfers, claimedUsers, userAddress) => {
    return (transfers || [])
      .filter(
        (t) =>
          t.from != chains.signUpPoolContractAdd.toLowerCase() &&
          t.from != chains.interactionContractAdd.toLowerCase() &&
          t.from != chains.flicsPoolContractAdd.toLowerCase()
      )
      .map((t) => ({
        type: "Transfer",
        direction:
          t.from.toLowerCase() === userAddress.toLowerCase() ? "out" : "in",
        amount: t.value,
        blockTimestamp: t.blockTimestamp,
      }));
  };

  const mapFilteredClaims = (claims, userAddress) => {
    return (claims || [])
      .filter((claim) => claim.user.toLowerCase() === userAddress.toLowerCase())
      .map((claim) => ({
        type: "Claim",
        direction: "in",
        amount: claim.amount,
        blockTimestamp: claim.timestamp,
      }));
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };

  return (
    <div className="page-page-wrapper flics-page-container">
      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Transactions</h1>
          
          {/* Dropdown filter */}
          <div className="transaction-filter">
            <label htmlFor="transaction-type">Filter by transaction type: </label>
            <select 
              id="transaction-type" 
              value={selectedType}
              onChange={handleFilterChange}
              className="transaction-type-dropdown"
            >
              {transactionTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          
          <div className="transaction-container">
            {loading ? (
              <p>Loading transactions...</p>
            ) : error ? (
              <p>{error}</p>
            ) : filteredTransactions.length === 0 ? (
              <p>No transactions found.</p>
            ) : (
              filteredTransactions.map((transaction, index) => (
                <div className="transaction-row" key={index}>
                  <div className="trans-details-wrap">
                    <div className="trans-title">
                      <span>{transaction.type}</span>
                    </div>
                    <div className="trans-date">
                      {formatDate(transaction.blockTimestamp)}
                    </div>
                  </div>
                  <div className="trans-bal">
                    <span>
                      <img src={dropdTokanIcon} alt="Token" />
                    </span>
                    <span>{Number(transaction.amount) / 1e18}</span>
                    <span>
                      <img
                        src={
                          transaction.direction === "in"
                            ? transInIcon
                            : transOutIcon
                        }
                        alt={transaction.direction === "in" ? "In" : "Out"}
                      />
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;