import { ethers } from "ethers";
import { flicsContractABI } from "../contract/flicsContractABI";

import { chains } from "../chain";

export const getFLicsStatus = async (flicsAddress) => {
	debugger
	const provider = new ethers.providers.JsonRpcProvider(
		chains.providerUrl
	);

	const contractInstance = new ethers.Contract(
		flicsAddress,
		flicsContractABI,
		provider
	);

	try {
		const tx = await contractInstance.flicsStatus();
		return tx;
		//@ts-ignore
	} catch (error) {
		console.log(error);
		console.log('Fetch Status Unsuccessful');
	}
};
