import React, { useState } from "react"
import Header from "../header/header";
import CustomButton from "../../../components/Button/button"
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UPDATE_NICKNAME } from "../../../axios/POST_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./name.css"

function Name({ setSliderPage }) {
  const spinner = useSpinner()
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState("")
  const { t, i18n } = useTranslation();

  // Validation function for nickname
  const isValidNickname = (nickname) => {
    const nicknameRegex = /^[a-zA-Z0-9_]+$/;
    return nicknameRegex.test(nickname);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    
    // Remove any spaces as they're typed
    const trimmedValue = value.replace(/\s/g, '');
    
    setName(trimmedValue);
    
    // Clear error when user starts typing
    if (nameError) {
      setNameError("");
    }
    
    // Validate input as user types
    if (trimmedValue && !isValidNickname(trimmedValue)) {
      setNameError("Nickname can only contain letters, numbers, and underscores");
    }
  }

  const handleName = async () => {
    // Validation checks
    if (!name) {
      setNameError(t("please-enter-nickname.1") || "Please enter a nickname");
      return;
    }

    if (!isValidNickname(name)) {
      setNameError("Nickname can only contain letters, numbers, and underscores");
      return;
    }

    try {
      spinner.setLoadingState(true);
      await UPDATE_NICKNAME({ nickname: name });
      spinner.setLoadingState(false);
      setSliderPage("gender");
    } catch (error) {
      spinner.setLoadingState(false);
      setNameError(error.response?.data?.message || "Error updating nickname");
      console.log(error, "this is the error in nickname updation");
    }
  }

  const handleBack = () => {
    setSliderPage("profile_pics");
  }

  return (
    <div className="signup-screen-wrapper">
      <Header />
      <div className="entry-wrap">
        <div className='name-text-container'>
          <div className='name-text'>
            {t("enter-your-nick-name.1")}
          </div>
        </div>
      </div>
      
      <div className="content-wrap">
        <div className='simple_input-container'>
          <div className='nickname-text'>{t("nick-name.1")}</div>
          <TextField 
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            id="outlined-basic"
            variant="outlined"
            size='small'
            value={name}
            onChange={handleChange}
            error={!!nameError}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "none"
                }
              }
            }}
          />
          {nameError && (
            <div style={{
              color: "#d32f2f",
              fontSize: "12px",
              marginTop: "8px",
              paddingLeft: "14px"
            }}>
              {nameError}
            </div>
          )}
        </div>
      </div>
                
      <div className="button-wrap">
        <CustomButton onClick={handleName}>{t("proceed.1")}</CustomButton>
      </div>
    </div>
  )
}

export default Name