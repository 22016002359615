import React, { useState, useEffect } from "react";
import TimelineNav from "../../../components/timelinenav/timelineNav";
import CustomButton from "../../../components/Button/button";
import TransactionConfirmationPopup from "../../../components/TransactionConfirmationPopup/TransactionConfirmationPopup";

import { TextField } from "@mui/material";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg";
import addIconWhite from "src/assets/images/addiconwhite.svg";
import activateIcon from "src/assets/images/activateicon.svg";
import swapIcon from "src/assets/images/swapicon.svg";
import upIcon from "src/assets/images/upicon.svg";
import infoIcon from "src/assets/images/informationcircle.svg";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";

import "../flics.css";
import { Col, Row } from "react-bootstrap";

import { sendTokensToActivateFlics } from "src/web3/ActivateFlics";
import { ROCCAActivate } from "src/web3/ROCCA";

import Balance from "src/pages/mywallet/components/balance";

import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

import { getWalletBalance } from "src/web3/getWalletBalance";

const { REACT_APP_LEGACY } = process.env;

function ActivateFlics({ amount, setAmount, setSliderPage }) {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const spinner = useSpinner();

  const [flicsAmount, setFlicsAmount] = useState(amount ?? 100);
  const [inputValue, setInputValue] = useState(amount?.toString() ?? "100");
  const [walletBalance, setWalletBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [isBalanceFetched, setIsBalanceFetched] = useState(false);

  const fetchBalance = async () => {
    try {
      spinner.setLoadingState(true);
      setIsLoading(true);
      setError("");
      setIsBalanceFetched(false);

      const balanceResult = await getWalletBalance();
      setWalletBalance(balanceResult);
      setIsBalanceFetched(true);

      // Only reset amount if it's more than balance
      if (flicsAmount > balanceResult) {
        setFlicsAmount(Math.min(100, balanceResult));
        setInputValue(Math.min(100, balanceResult).toString());
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
      setError("Failed to fetch wallet balance");
    } finally {
      setIsLoading(false);
      spinner.setLoadingState(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Allow empty string or numbers only
    if (value === "" || /^\d*$/.test(value)) {
      setInputValue(value);

      // Convert to number for flicsAmount, but only if it's not empty
      const numValue = value === "" ? 0 : Number(value);
      setFlicsAmount(numValue);
    }
  };

  const handleSliderChange = (_, newValue) => {
    const validatedValue = Math.min(newValue, Math.min(walletBalance, 100000));
    setFlicsAmount(validatedValue);
    setInputValue(validatedValue.toString());
  };

  // Validate for activation
  const isValidAmount =
    flicsAmount >= 100 && flicsAmount <= Math.min(walletBalance, 100000);
  const canActivate = !isLoading && !error && isValidAmount;

  // Show appropriate error message only after balance is fetched
  const getErrorMessage = () => {
    if (!isBalanceFetched) return ""; // Don't show any validation errors until balance is fetched
    if (error) return error;
    if (flicsAmount > walletBalance) return "Amount exceeds wallet balance";
    if (flicsAmount !== 0 && flicsAmount < 100)
      return "Minimum amount is 100 DRPD";
    return "";
  };

  const transactionDescription = `You have successfully activated ROCCA worth ${flicsAmount} DRPD`;

  return (
    <div className="page-page-wrapper flics-page-container">
      <div className="inner-pages-container">
        <div className="half-pnk">
          <h1 className="page-title">Activate ROCCA</h1>
          <div className="flics-wallet-balance">
            Wallet Balance{" "}
            <span>
              <img src={dropdTokanIcon} />
              <Balance />
            </span>
          </div>
        </div>

        <div className="inner-pages-container-wrap">
          <div className="flics-popup-conainer">
            <div className="flics-txt">Enter ROCCA amount you want to buy</div>

            <div className="amount-input-field">
              <TextField
                id="outlined-start-adornment"
                variant="outlined"
                size="small"
                disabled={isLoading}
                error={!!getErrorMessage()}
                helperText={getErrorMessage()}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "1px solid",
                      borderRadius: "12px",
                      borderColor: "#000000de",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={dropdTokanIcon} />
                    </InputAdornment>
                  ),
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <Row>
            <Col lg={3} style={{ marginLeft: "-10px" }}>
              <span
                style={{
                  float: "right",
                  color: "#6A7587",
                  fontSize: 10,
                }}
              >
                Min: 100
              </span>
            </Col>
            <Col lg={7}>
              <span
                style={{
                  float: "right",
                  color: "#6A7587",
                  fontSize: 10,
                }}
              >
                Max: {isBalanceFetched ? Math.min(walletBalance, 100000) : "-"}
              </span>
            </Col>
          </Row>
          <div>
            <Box sx={{ width: 300, marginLeft: 3 }}>
              <Slider
                value={flicsAmount || 0}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={0}
                max={Math.min(walletBalance, 100000)}
                disabled={isLoading || !isBalanceFetched}
                onChange={handleSliderChange}
              />
            </Box>
          </div>

          <div className="button-wrap">
            <CustomButton
              disabled={!canActivate || !isBalanceFetched}
              onClick={async () => {
                try {
                  spinner.setLoadingState(true);

                  if (!authProvider) {
                    const smartWallet = await initWallet();
                    authProvider = smartWallet;
                  }
                  const txSuccess = REACT_APP_LEGACY == "true"
                    ? await sendTokensToActivateFlics(
                        authProvider,
                        flicsAmount.toString()
                      )
                    : await ROCCAActivate(authProvider, flicsAmount.toString());

                  if (txSuccess) {
                    setShowConfirmationPopup(true);
                    setTransactionSuccess(true);
                  }
                } catch (error) {
                  console.error("Error activating ROCCA:", error);
                  setError("Failed to activate ROCCA");
                } finally {
                  spinner.setLoadingState(false);
                }
              }}
            >
              Activate ROCCA
            </CustomButton>
          </div>

          {transactionSuccess && (
            <TransactionConfirmationPopup
              isOpen={showConfirmationPopup}
              onClose={() => {
                setShowConfirmationPopup(false);
                setAmount(flicsAmount);
                setSliderPage("flicsmain");
              }}
              amount={flicsAmount}
              transactionType={transactionDescription}
              onRefreshBalance={fetchBalance}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivateFlics;
