import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";

import { chains } from "../chain";

export const getWalletBalance = async () => {

	const contractAddress = chains.dropdContractAdd;
	const provider = new ethers.providers.JsonRpcProvider(
		chains.providerUrl
	);

	const contractInstance = new ethers.Contract(
		contractAddress,
		contractABI,
		provider
	);

	const walletAddress = localStorage.getItem("wallet_address");

	try {
		const tx = await contractInstance.balanceOf(walletAddress);
		
		if (tx) {
			const activeAmount = parseFloat(tx)
			const smallAmount = (activeAmount / 1e18);
			return smallAmount.toFixed(2);
		}
		//@ts-ignore
	} catch (error) {
		console.log(error);
		console.log('Fetch Balance Unsuccessful');
		return 0;
	}
};
