import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';
import { getFlicsSent } from "src/axios/POST_API";

import moneyIcon from "src/assets/images/money-white.svg";

const RoccaRequestSent = ({roccaSentDetails}) => {
  const [flicsSent, setFlicsSent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const flicsSentList = await getFlicsSent();
        setFlicsSent(flicsSentList);
      } catch (error) {
        console.error('Error fetching ROCCA request list:', error);
      }
    };

    fetchData(); 
  }, []);

  return (
    <div className="flics-tabs-container">
      <div className="flics-options">
      {flicsSent.map((flics, index) => (
        <button onClick={() => roccaSentDetails(flics)} style={{ backgroundColor: '#EC1C80', color: '#ffffff' }}>
          <span>Shared ROCCA<span style={{ marginLeft: '10px', fontWeigt: 'bold'}}><img src={moneyIcon} /> {flics.flics_amount[0] / 1e18}</span></span>
          <span>To {flics.nickname}</span>
        </button>
      ))}
      </div>
    </div>
  );
};

RoccaRequestSent.prototype = {
    roccaSentDetails: PropTypes.func,
}

export default RoccaRequestSent;
