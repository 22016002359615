import React, { useEffect, useRef, useState } from 'react';
import avatar from "src/assets/images/avatar.jpeg";
import "./timeline.css";
import Simpleinput from "../../components/simpleinput/simpleinput";
// import Timelinecard from "./timelinecard/timelinecard";
import PostCard from "../../components/PostCard/PostCard";
import TimelineNav from "../../components/timelinenav/timelineNav";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { GET_TIMELINE } from "../../axios/GET_API";
import { LIKE_POST } from "../../axios/POST_API";
import SkeletonLoader from "../../components/SkeletonLoader/skeletonLoader";
import { useSkeleton } from "../../context/skeletoncontext/skeletoncontext";
import { useParams } from "react-router-dom";
import FloatingToolbar from "../../components/FloatingToolbar";
import RetrieveToken from "../../utils/HelperFunctions/RetrieveToken";
import CustomToast from "../../components/CustomToast/CustomToast";

// Firebase Notification Permissions

import { messaging, getToken, onMessage } from "../../firebase";
import NotificationPermissionPopup from "../../components/NotificationPermissionPopup/NotificationPermissionPopup";
import { USER_META_FCM_TOKEN } from "../../axios/POST_API";
import { getMessaging, isSupported } from 'firebase/messaging';

const { REACT_APP_CDN_HOST } = process.env;
const PAGE_SIZE = 20;

function Timeline() {
  const { did } = useParams();
  const skeleton = useSkeleton();
  const spinner = useSpinner();
  const history = useNavigate();
  const [posts, setPosts] = useState([]);
  const [timelineData, setTimelineData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadingRef = useRef(null);
  const [timelineError, setTimelineError] = useState()
  const [timelineNav, setTimelineNav] = useState()
  const [friendRequestStatus, setFriendRequestStatus] = useState(null);
  const [showFriendRequestBar, setShowFriendRequestBar] = useState(false);
  const [friendRequestBarMessage, setFriendRequestBarMessage] = useState('');
 
  const initializeMessaging = async () => {
    const supported = await isSupported();
    if (!supported) {
      console.warn('Firebase Messaging is not supported in this browser.');
      return null;
    }else{
      console.log("browser supported") 
    }

    const messaging = getMessaging();
    // Add messaging logic here (e.g., getToken, onMessage, etc.)
    return messaging;
  };

  initializeMessaging().catch((error) => {
    console.error('Error initializing Firebase Messaging:', error);
  });

  // Constants for notification states
  const NOTIFICATION_STATES = {
    PERMISSION_GRANTED: 'notificationPermissionGranted',
    PERMISSION_DENIED: 'notificationPermissionDenied',
    SUCCESS_TOAST_SHOWN: 'successToastShown',
    LAST_PROMPT_DATE: 'lastNotificationPromptDate'
  };

  // Add these new states
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [notificationBlocked, setNotificationBlocked] = useState(false);
  const [notificationErrorOccurred, setNotificationErrorOccurred] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [permissionRetries, setPermissionRetries] = useState(0);

  // Enhanced permission request function
  const requestNotificationPermission = async (grantPermission) => {
    if (!grantPermission) {
      handlePermissionDenied();
      return;
    }

    try {
      spinner.setLoadingState(true);

      // First request browser permission
      const browserPermission = await Notification.requestPermission();
      if (browserPermission !== 'granted') {
        handlePermissionDenied();
        return;
      }

      const token = await getToken(messaging, {
        vapidKey: "BDqNdTe7Wj9RdOTdOBDiX9WC0gMDYTICs40ZyKU8by3aLXqPUukvKfaUB5VAN7aKQ3O45F_W3JZeS1XUM595HCA"
      });

      if (!token) {
        throw new Error('Failed to generate FCM token');
      }

      await USER_META_FCM_TOKEN(token);
      handlePermissionGranted();

    } catch (error) {
      console.error("Notification setup failed:", error);
      handlePermissionError(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  // Helper functions for handling different permission states
  const handlePermissionGranted = () => {
    localStorage.setItem(NOTIFICATION_STATES.PERMISSION_GRANTED, 'true');
    localStorage.removeItem(NOTIFICATION_STATES.PERMISSION_DENIED);
    localStorage.setItem(NOTIFICATION_STATES.LAST_PROMPT_DATE, new Date().toISOString());
    setShowPermissionPopup(false);
    
    if (!localStorage.getItem(NOTIFICATION_STATES.SUCCESS_TOAST_SHOWN)) {
      setShowSuccessToast(true);
      localStorage.setItem(NOTIFICATION_STATES.SUCCESS_TOAST_SHOWN, 'true');
    }
  };

  const handlePermissionDenied = () => {
    localStorage.setItem(NOTIFICATION_STATES.PERMISSION_DENIED, 'true');
    localStorage.removeItem(NOTIFICATION_STATES.PERMISSION_GRANTED);
    localStorage.setItem(NOTIFICATION_STATES.LAST_PROMPT_DATE, new Date().toISOString());
    // setNotificationBlocked(true);
    setShowPermissionPopup(false);
  };

  const handlePermissionError = (error) => {
    // setNotificationErrorOccurred(true);
    setShowPermissionPopup(false);
    console.error('Notification permission error:', error);
  };

  // Enhanced permission check function
  const checkNotificationPermission = async () => {
    const permission = Notification.permission;
    const lastPromptDate = localStorage.getItem(NOTIFICATION_STATES.LAST_PROMPT_DATE);
    const isDenied = localStorage.getItem(NOTIFICATION_STATES.PERMISSION_DENIED);
    const daysSinceLastPrompt = lastPromptDate 
      ? Math.floor((new Date() - new Date(lastPromptDate)) / (1000 * 60 * 60 * 24))
      : null;

    // Don't show prompt if:
    // 1. Permission was denied less than 7 days ago
    // 2. We've tried more than 3 times
    if (isDenied && daysSinceLastPrompt && daysSinceLastPrompt < 7) {
      return;
    }

    if (permissionRetries >= 3) {
      return;
    }

    switch (permission) {
      case 'default':
        if (!isDenied) {
          setShowPermissionPopup(true);
          setPermissionRetries(prev => prev + 1);
        }
        break;
      
      case 'granted':
        handlePermissionGranted();
        await requestNotificationPermission(true);
        break;
      
      case 'denied':
        handlePermissionDenied();
        break;
    }
  };

  // Enhanced useEffect
  useEffect(() => {
    checkNotificationPermission();
    
    // Cleanup function
    return () => {
      spinner.setLoadingState(false);
    };
  }, []);

  // Enhanced toast handler
  const handleCloseToast = () => {
    setNotificationBlocked(false);
    setNotificationErrorOccurred(false);
    setShowSuccessToast(false);
    setPermissionRetries(0); // Reset retries when user closes toast
  };
  
  // Intersection Observer setup
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && hasMore && !isLoading) {
        setCurrentPage(prev => prev + 1);
      }
    }, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, isLoading]);

  // Fetch timeline data
  const fetchTimelineData = async (page) => {
    try {
      setIsLoading(true);
      const response = await GET_TIMELINE(page);
      
      if (page === 1) {
        // For first page, set both posts and timeline data
        setPosts(response?.data?.posts || []);
        setTimelineData(response);
      } else {
        // For subsequent pages, only append new posts
        setPosts(prevPosts => [...prevPosts, ...(response?.data?.posts || [])]);
        
        // Update other timeline data if needed
        setTimelineData(prev => ({
          ...prev,
          data: {
            ...prev.data,
            is_profile_complete: response.data.is_profile_complete,
            message: response.data.message,
            status: response.data.status,
            user_profile: response.data.user_profile
          }
        }));
      }

      setHasMore(response?.data?.posts?.length === PAGE_SIZE);
      localStorage.setItem("timeline.response", JSON.stringify(response));
      setTimelineError(null);
    } catch (error) {
      console.error("Timeline fetch error:", error);
      setTimelineError(error?.response?.data?.message || "Failed to load posts");
    } finally {
      setIsLoading(false);
      skeleton.setLoadingState(false);
      spinner.setLoadingState(false);
    }
  };

  const likePost = (pid) => {
    try {
      LIKE_POST(pid);
    } catch (error) {
      console.log(error);
    }
  };
  const handleProfile = () => {
    history("/profile");
  };

  const handlePost = () => {
    history("/createpost");
  };

  useEffect(() => {
    const token = RetrieveToken();
    if (!token) {
      history("/login");
      return;
    }

    // Only load cached data on initial load
    if (currentPage === 1) {
      const cachedResponse = localStorage.getItem("timeline.response");
      if (cachedResponse) {
        const parsedResponse = JSON.parse(cachedResponse);
        setPosts(parsedResponse?.data?.posts || []);
        setTimelineData(parsedResponse);
      }
    }

    fetchTimelineData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (timelineData) {
      if (timelineData?.data.is_profile_complete == false) {
        if(timelineData?.data.incomplete_profile_label?.toLowerCase() === "claim_amount"){
          history("/userdetails/claim")
        } else{
          history("/userdetails/" + timelineData?.data.incomplete_profile_label)
        }
      }
      skeleton.setLoadingState(false);
    }

  }, [timelineData])

  let userProfileImage = "";
  if (timelineData?.data?.user_profile?.profile_pics != null) {
    userProfileImage = timelineData?.data?.user_profile?.profile_pics[0];
    userProfileImage =
      `${REACT_APP_CDN_HOST}` + userProfileImage.replace("//", "/");
  }


  return (
    <div className="timeline-container" style={
      showFriendRequestBar ? { 'marginTop': '8%' } : {}
    }
    >
      {showFriendRequestBar &&
        (<div className="floatingToolbar" style={{ 'top': '2%' }}>
          <span className="profile-icons-container">
            {friendRequestBarMessage}
          </span>
          {
            (friendRequestStatus === 'success' || friendRequestStatus === 'error')
            &&
            (<span className="close-icon" onClick={() => setShowFriendRequestBar(false)}>x</span>)
          }
        </div>)
      }
      {skeleton.isLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <TimelineNav />
          <div className="search-box-container">
            <div
              onClick={handlePost}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="input-container-box"><div className="input-text">Whats on your mind ?</div></div>
            </div>
            <div onClick={handleProfile} className="avatar-container">
              <img
                id="cursor"
                className="avatar-image"
                src={userProfileImage}
                alt="avatar"
              ></img>
            </div>
          </div>

          {/* Posts List */}
          <div style={{paddingBottom: "60px" }}>
            {posts.map((item) => {
              const postImg = item.images?.[0] ? `${REACT_APP_CDN_HOST}${item.images[0]}` : "";
              const profileImage = item.post_from.profile_pics?.[0] 
                ? `${REACT_APP_CDN_HOST}${item.post_from.profile_pics[0].replace("//", "/")}` 
                : "";
              
              return (
                <div key={`${item.pid}-${currentPage}`} className="mb-1">
                  <PostCard
                    postComments={item.comments.length}
                    post_id={item.pid}
                    did={item.post_from.did}
                    postProfilePic={profileImage}
                    userName={item.post_from.nick_name}
                    postText={item.text}
                    postLikes={item.likes}
                    profileLikeCount={item.post_from.like_count}
                    postImage={postImg}
                    postDate={moment(item.created_at).format("MMM D, h:mm a")}
                    memberSince={moment(item.post_from.joined_at).fromNow()}
                    kundliAttributes={item.post_from.kundli_attributes}
                    zone={item.post_from.zone}
                    is_friend={item.post_from.is_friend}
                    is_friend_requested_to={item.post_from.is_friend_requested_to}
                    is_friend_requested_from={item.post_from.is_friend_requested_from}
                    is_liked={item.post_from.is_like_to}
                    walletAddress={item.post_from.wallet_address}
                    showFriendRequestStatus={(status, error) => {
                      setShowFriendRequestBar(true);
                      setFriendRequestStatus(status);
                      setFriendRequestBarMessage(
                        status === 'inprogress' ? "Friend request in progress!" :
                        status === 'success' ? "Friend request sent successfully!" :
                        "Friend request failed!"
                      );
                    }}
                    is_post_like_to={item.is_post_like_to}
                    club_id={item.post_from.club_id}
                    club_name={item.post_from.club_name}
                    club_profile_image={REACT_APP_CDN_HOST + item.post_from.club_profile_image}
                    club_created_at={moment(item.post_from.club_created_at).fromNow()}
                    club_city={item.post_from.club_city}
                    club_locality={item.post_from.club_locality}
                    show_header={true}
                    all_likes={item.post_from.all_likes}
                    current_month_likes={item.post_from.current_month_likes}
                  />
                </div>
              );
            })}

            {/* Loading and Error States */}
            <div ref={loadingRef} className="text-center">
              {isLoading && (
                <div className="flex flex-col items-center justify-center py-4">
                  <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
                  <div className="mt-2 text-sm text-gray-500">Loading more posts...</div>
                </div>
              )}
              {!hasMore && !isLoading && (
                <div className="text-gray-500 py-4">No more posts to load</div>
              )}
              {timelineError && !isLoading && (
                <div className="text-red-500 py-4">{timelineError}</div>
              )}
            </div>
          </div>

          <FloatingToolbar timelineNav={timelineNav} setTimelineNav={setTimelineNav} />

        </div>
      )}
      <div className="timeline_error">{timelineError}</div>

      {/* Render the Notification Permission Popup if needed */}
      {showPermissionPopup && (
        <NotificationPermissionPopup 
          onRequestPermission={requestNotificationPermission}
          retryCount={permissionRetries}
        />
      )}
      {notificationBlocked && (
        <CustomToast
          message="Notifications are currently blocked. To receive updates, please enable notifications in your browser settings."
          onClose={handleCloseToast}
          type="warning"
        />
      )}
      {notificationErrorOccurred && (
        <CustomToast
          message="We couldn't set up notifications. Please check your browser settings and try again later."
          onClose={handleCloseToast}
          type="error"
        />
      )}
      {showSuccessToast && (
        <CustomToast
          message="Notifications have been enabled successfully!"
          onClose={handleCloseToast}
          type="success"
        />
      )}

    </div>
  );
}

export default Timeline;