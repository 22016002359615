import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { MY_INCOME } from "../../axios/POST_API";

// import assets
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import incomeGiver from "src/assets/images/incomegiver.svg";
import swapIcon from "src/assets/images/swapicon.svg";

// import components
import TimelineNav from "../../components/timelinenav/timelineNav";
import TransactionConfirmationPopup from "../../components/TransactionConfirmationPopup/TransactionConfirmationPopup";
import TransactionFailurePopup from "../../components/TransactionFailurePopup/TransactionFailurePopup"

import { GET_CLAIM_REWARD_SIGNATURE } from "../../axios/GET_API";
import { claimReward } from "../../web3/ClaimReward";

import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

import { getMonthName, getMonthNameShort } from 'src/utils/monthUtils';

import {
  useSpinner,
  UseSpinnerProvider,
} from "../../context/loaderContext/globalSpinnerContext";

import "./myincome.css";
import { ethers } from "ethers";

function MyIncome() {


  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const history = useNavigate();
  const spinner = useSpinner();

  const [myIncome, setMyIncome] = useState(null);
  const [currentMonthIncome, setCurrentMonthIncome] = useState(null);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [showFailurePopup, setShowFailurePopup] = useState(false);
  const transactionDescription = `You have successfully Claimed ${myIncome?.income_amount} DRPD`;

  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [checkAttempts, setCheckAttempts] = useState(0);
  const MAX_CHECK_ATTEMPTS = 5; // Limited to 5 attempts as requested
  const CHECK_INTERVAL = 3000; // Check every 3 seconds

  const fetchIncomeData = async () => {
    try {
      spinner.setLoadingState(true);
      const current_month = 'no';
      const response = await MY_INCOME(current_month);
      setMyIncome(response?.data);
      spinner.setLoadingState(false);
    } catch (error) {
      console.error("Income fetch error:", error);
      spinner.setLoadingState(false);
    }
  };

  const fetchCurrentMonthIncome = async () => {
    try {
      spinner.setLoadingState(true);
      const current_month = 'yes';
      const response = await MY_INCOME(current_month);
      setCurrentMonthIncome(response?.data);
      spinner.setLoadingState(false);
    } catch (error) {
      console.error("Income fetch error:", error);
      spinner.setLoadingState(false);
    }
  };

  useEffect(() => {
    fetchIncomeData();
    fetchCurrentMonthIncome();
  }, []);


  // Added this useEffect for status checking
  useEffect(() => {
    let statusTimer;
    
    if (isCheckingStatus && checkAttempts < MAX_CHECK_ATTEMPTS) {
      statusTimer = setTimeout(async () => {
        // Call the existing function instead of duplicating code
        await fetchIncomeData();
        
        // Check if income_claimed has changed to true
        if (myIncome?.income_claimed === true) {
          // Stop checking when status changes
          setIsCheckingStatus(false);
          console.log("Income claim status updated successfully!");
        } else {
          // Continue checking
          setCheckAttempts(prevCount => prevCount + 1);
        }
      }, CHECK_INTERVAL);
    } else if (checkAttempts >= MAX_CHECK_ATTEMPTS) {
      // Stop checking after max attempts
      setIsCheckingStatus(false);
      console.log("Max status check attempts reached. Status may not have updated.");
    }
    
    // Cleanup timer on unmount or when checking stops
    return () => {
      if (statusTimer) clearTimeout(statusTimer);
    };
  }, [isCheckingStatus, checkAttempts, myIncome?.income_claimed]);

  const changeToTransaction = () => {
    history("/transactions?type=Income%20Claim");
  };

  const handleClaim = async () => {
    spinner.setLoadingState(true);
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }
      const response = await GET_CLAIM_REWARD_SIGNATURE();

      const signV = response.v;
      const signR = response.r;
      const signS = response.s;

      const nonce = response.nonce;
      const time = response.timestamp;
      const claimValue = response.claim_amount;
      const nextClaimTime = response.next_claim_time;
      
      const signArray = [signV, signR, signS, nonce, time];

      const txReceipt = await claimReward(authProvider, claimValue, nextClaimTime, signArray);

      if (txReceipt) {
        setShowConfirmationPopup(true);
        setTransactionSuccess(true);

        // Start status checking when transaction is successful
        setIsCheckingStatus(true);
        setCheckAttempts(0);
      }else{
        setShowFailurePopup(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  return (
    
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="page-page-wrapper flics-page-container">
          <div className="pnkbg">
            <TimelineNav />
          </div>

          { myIncome && (
          <div className="inner-pages-container">
            <div className="half-pnk">
              <div className="wallet-title-menu-wrap">
                <h1 className="page-title">My Income</h1>
              </div>
            </div>

            <div className="inner-pages-container-wrap">
              <div className="income-wrapper">
                <div className="income-card-container">
                  <div className="income-popup-conainer">
                    <div className="add-drpd-btn-wrap">
                      <span>My Income</span>
                      <span></span>
                    </div>

                    <div className="balance">
                      <span>
                        <img src={dropdTokanIcon} />
                      </span>
                      <span>{myIncome?.income_amount}</span>
                    </div>

                    <div className="income-month">
                      <span>{getMonthName(myIncome?.month)} / {myIncome?.year}</span>
                    </div>

                    <div className="income-formula">
                      <span>Likes</span>
                      <span className="txt-blue sign">=</span>
                      <span style={{ marginRight: "10px" }}>
                        <img src={dropdTokanIcon} />
                      </span>
                      <span className="txt-blue">DRPD</span>
                    </div>
                  </div>

                  <div className="wallet-monthly-table">
                    <table>
                      <tbody>
                        <tr style={{ backgroundColor: "#ffffff" }}>
                          <th colSpan="3">This month</th>
                          <th>Avg. Unit value</th>
                          <th>Total</th>
                        </tr>
                        <tr style={{ backgroundColor: "#FAF3FF" }}>
                          <td className="lbl">Likes</td>
                          <td className="val">{myIncome?.my_likes_count}</td>
                          <td className="lbl">x</td>
                          <td className="val">{myIncome?.income_unit}</td>
                          <td className="total"><span style={{ marginRight: "10px" }}><img src={dropdTokanIcon} /></span>{myIncome?.income_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="income-card-container">
                <div className="income-popup-conainer pink-bg">
                  <div className="add-drpd-btn-wrap">
                    <span>Expected Income</span>
                  </div>

                  <div className="balance">
                    <span>
                      <img src={dropdTokanIcon} />
                    </span>
                    <span>{currentMonthIncome?.income_amount}</span>
                  </div>

                  <div className="income-month">
                    <span>{getMonthName(currentMonthIncome?.month)} / {myIncome?.year}</span>
                  </div>

                  <div className="income-formula">
                    <span>Likes</span>
                    <span className="txt-blue sign">=</span>
                    <span style={{ marginRight: "10px" }}>
                      <img src={dropdTokanIcon} />
                    </span>
                    <span className="txt-blue">DRPD</span>
                  </div>
                </div>
                <div className="wallet-monthly-table">
                  <table>
                    <tbody>
                      <tr style={{ backgroundColor: "#ffffff" }}>
                        <th colSpan="3">This month</th>
                        <th>Avg. Unit value</th>
                        <th>Total</th>
                      </tr>
                      <tr style={{ backgroundColor: "#FAF3FF" }}>
                        <td className="lbl">Likes</td>
                        <td className="val">{currentMonthIncome?.my_likes_count}</td>
                        <td className="lbl">x</td>
                        <td className="val">{currentMonthIncome?.income_unit}</td>
                        <td className="total"><span style={{ marginRight: "10px" }}><img src={dropdTokanIcon} /></span>{currentMonthIncome?.income_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {myIncome?.lifetime_income_amount && (
                <div className="wallet-tabs-container">
                  <button className="transaction" onClick={changeToTransaction}>
                    <span>Lifetime Claimed Income</span>{" "}
                    
                    <span className="tab-icon" style={{ marginRight: "10px", marginLeft: "auto" }}>
                      <img src={dropdTokanIcon} />
                    </span>

                    <span>{myIncome?.lifetime_income_amount}</span>
                  </button>
                </div>
              )}

            </div>

            <div className="inner-pages-container-wrap">
              <div className="button-wrap">
                {myIncome?.income_amount === 0.0 ? (
                  <Button
                    variant="contained"
                    className="button disabled-button"
                    disabled
                  >
                    No Income to Claim
                  </Button>
                ) : myIncome?.income_claimed ? (
                  <Button
                    variant="contained"
                    className="button disabled-button"
                    disabled
                  >
                    Already Claimed
                  </Button>
                ) : (
                  <Button
                    onClick={handleClaim}
                    variant="contained"
                    className="button"
                  >
                    Claim
                  </Button>
                )}
              </div>
            </div>


            {transactionSuccess && (
              <TransactionConfirmationPopup 
                isOpen={showConfirmationPopup}
                onClose={() => {
                  setShowConfirmationPopup(false);
                }}
                amount={myIncome?.income_amount}
                transactionType={transactionDescription}
              />
            )}

            <TransactionFailurePopup 
                isOpen={showFailurePopup}
                onClose={() => {
                  setShowFailurePopup(false);
                }}
                amount={myIncome?.income_amount}
                transactionType={`Your claim of ${myIncome?.income_amount} DRPD could not be processed`}
              />

          </div>
          )}
        </div>
      </div>
    
  );
}

export default MyIncome;
