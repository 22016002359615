import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './ProfileImageSlider.css';

const ProfileImageSlider = ({ 
  profileData, 
  cdnHost 
}) => {
  // Prepare image URLs
  const profileImages = useMemo(() => {
    // Check if profileData is an array
    if (!Array.isArray(profileData)) {
      console.warn('ProfileImageSlider: profileData is not an array');
      return [];
    }

    // Process images by combining with CDN host
    return profileData.map(pic => `${cdnHost}${pic}`);
  }, [profileData, cdnHost]);

  // If no images, return null
  if (profileImages.length === 0) return null;

  return (
    <div className="profile-image-slider-container"> {/* Fixed height container */}
      {/* Image count badge */}
      {profileImages.length > 1 && (
        <div className="slide-count">
          {profileImages.length} images
        </div>
      )}

      <Swiper
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ 
          clickable: true,
          dynamicBullets: true 
        }}
        className="profile-image-slider"
      >
        {profileImages.map((imageUrl, index) => (
          <SwiperSlide key={index}>
            <div className="image-slide">
              <img 
                src={imageUrl} 
                alt={`Profile ${index + 1}`} 
                className="profile-cover-image "
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: '10px 10px 0px 0px'
                }}
                onError={(e) => {
                  console.error('Image load error:', imageUrl);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProfileImageSlider;